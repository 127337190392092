import React from "react";
import { ReactSortable } from "react-sortablejs";
import { useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import FieldValueListItemNormal from "./fieldValueListItemNormal";
import SummaryLotsTable from "../summaryLotsTable";
import FieldValueListItemAdmin from "./fieldValueListItemAdmin";
import { useComponentsStore } from "../../../../../../Stores/componentsStore";
import useWindowSize from "../../../../../../Hooks/useWindowSize";
import { useVademecumStore } from "../../../../../../Stores/vademecumStore";

export default function FieldValueLists({ adminSwitch }) {
  const size = useWindowSize();
  const [isDark] = useComponentsStore((state) => [state.isDark]);

  const [setSummaryFieldValues, summaryFieldValues] = useViewEditTenderStore(
    (state) => [state.setSummaryFieldValues, state.summaryFieldValues]
  );

  return (
    <div>
      {!adminSwitch ? (
        <React.Fragment>
          <ReactSortable
            handle=".tc-move-summary-field-arrows"
            list={summaryFieldValues.map((x) => ({
              ...x,
              chosen: true,
            }))}
            setList={(newState) => {
              newState = newState.map((x, idx) => ({
                ...x,
                position: idx,
              }));
              setSummaryFieldValues(newState);
              useVademecumStore.getState().refreshPDF();
            }}
            className="d-flex flex-column tc-flex-gap-10px"
          >
            {summaryFieldValues
              .filter((field) => field.return_type === "TEXT")
              .map((field, idx) => (
                <FieldValueListItemNormal key={idx} field={field} />
              ))}
            <div className="mt-3 px-4">
              {summaryFieldValues
                .filter((field) => field.return_type === "CSV")
                .map((field, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center tc-flex-gap-10px"
                  >
                    <SummaryLotsTable
                      adminSwitch={adminSwitch}
                      size={size}
                      field={field}
                      editable={false}
                      saveField={() => {}}
                    />
                  </div>
                ))}
            </div>
          </ReactSortable>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ReactSortable
            handle=".tc-move-summary-field-arrows"
            list={summaryFieldValues.map((x) => ({
              ...x,
              chosen: true,
            }))}
            setList={(newState) => {
              newState = newState.map((x, idx) => ({
                ...x,
                position: idx,
              }));
              setSummaryFieldValues(newState);
              useVademecumStore.getState().refreshPDF();
            }}
            className="d-flex flex-column tc-flex-gap-10px"
          >
            {summaryFieldValues
              .filter((field) => field.return_type === "TEXT")
              .map((field, idx) => (
                <FieldValueListItemAdmin
                  key={`${idx}_${field.state}`}
                  field={field}
                />
              ))}
          </ReactSortable>
          <div className={`mt-3 ps-4 ${isDark ? "table-dark" : ""}`}>
            {summaryFieldValues
              .filter((field) => field.return_type === "CSV")
              .map((field, idx) => (
                <SummaryLotsTable
                  key={idx}
                  size={size}
                  field={field}
                  editable={true}
                />
              ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
