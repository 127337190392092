import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableRows from "./TableRows";

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: 12,
  },
});

const ItemsTable = ({ rows }) => (
  <View style={styles.tableContainer}>
    {/*<TableHeader />*/}
    <TableRows rows={rows} />
    {/*<TableFooter items={data.items} />*/}
  </View>
);

export default ItemsTable;
