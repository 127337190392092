import React from "react";
import ScreenSpinner from "../../Components/ScreenSpinner";
import { useAuth } from "../../Services/auth";
import devDebug from "../../Helpers/debug";
import { useTabStore } from "../../Stores/tabStore";
import { useMapsStore } from "../../Stores/mapsStore";
import { useDefaultStrategyStore } from "../../Stores/defaultStrategyStore";
import { useTenderStrategyStore } from "../../Stores/tenderStrategyStore";
import { useCPVStore } from "../../Stores/cpvStore";
import { useAuthStore } from "../../Stores/authStore";

let StrategyContext = React.createContext();

export function useStrategies() {
  return React.useContext(StrategyContext);
}

export function StrategyProvider({ children }) {
  //devDebug(`strategyService -> StrategyProvider loaded`);
  let [firstRun, setFirstRun] = React.useState(true);
  let [existingStrategies, setExistingStrategies] = React.useState({});
  const auth = useAuth();
  const apiService = auth.apiService;

  const [verifyTabsExist] = useTabStore((state) => [state.verifyTabsExist]);

  const [verifyMapDataExists, setNUTSMapStage] = useMapsStore((state) => [
    state.verifyMapDataExists,
    state.setNUTSMapStage,
  ]);

  const [verifyCPVDataExists, setCPVStage] = useCPVStore((state) => [
    state.verifyCPVDataExists,
    state.setCPVStage,
  ]);

  const [compileDefaultStrategies] = useDefaultStrategyStore((state) => [
    state.compileDefaultStrategies,
  ]);

  const [setEditableStrategies, strategiesStatus, setStrategiesStatus] =
    useTenderStrategyStore((state) => [
      state.setEditableStrategies,
      state.strategiesStatus,
      state.setStrategiesStatus,
    ]);

  const reloadStrategies = () => {
    const controller = new AbortController();

    devDebug(`strategyService -> reloading strategies`);
    apiService.axiosGET(
      `user/preferences/strategies/`, //endpoint
      controller.signal, //signal
      reloadStrategiesReceived, //successFn, receives resp and data
      auth.newErrorHandler, //error AlerterFn
      "There was an error retrieving user's tender strategies", //error message
      strategiesError, //errorFn (gets error back)
      {
        section: "strategyService -> get strategies",
      } //data (gets handed back to success fn)
    );
  };

  const reloadStrategiesReceived = (resp) => {
    devDebug(`strategyService -> reloadStrategiesReceived: `, resp);
    setNUTSMapStage("reloading");
    //setCPVStage("reloading"); //not currently needed, will be needed when cpv stats added again
    setStrategiesStatus("reloading");

    let strategies = {};
    for (let strat of resp) {
      strategies[strat.id] = strat;
      if (useAuthStore.getState()?.user?.subscription?.product_type === "only_italy") {
        let newNuts = [];

        for (let nut of strategies[strat.id].nuts) {
          if (
            nut.includes("IT") &&
            useAuthStore.getState()?.user?.subscription?.product_type === "only_italy"
          ) {
            newNuts.push(nut);
          }
        }
        strategies[strat.id].nuts = newNuts;
      }
    }
    setExistingStrategies(strategies);
    setEditableStrategies(strategies, "tender_strategy");
    compileDefaultStrategies(strategies);
    verifyTabsExist("tender_strategy");
    verifyMapDataExists();
    verifyCPVDataExists();
    setStrategiesStatus("loaded");
  };

  const getStrategies = (controller) => {
    devDebug(`strategyService -> fetching strategies`);
    apiService.axiosGET(
      `user/preferences/strategies/`, //endpoint
      controller.signal, //signal
      strategiesReceived, //successFn, receives resp and data
      auth.newErrorHandler, //error AlerterFn
      "There was an error retrieving user's tender strategies", //error message
      strategiesError, //errorFn (gets error back)
      {
        section: "strategyService -> get strategies",
      } //data (gets handed back to success fn)
    );
  };

  const strategiesReceived = (resp) => {
    devDebug(`strategyService -> strategiesReceived: `, resp);
    setStrategiesStatus("loading");
    let strategies = {};
    for (let strat of resp) {
      strategies[strat.id] = strat;
      if (useAuthStore.getState()?.user?.subscription?.product_type === "only_italy") {
        let newNuts = [];

        for (let nut of strategies[strat.id].nuts) {
          if (
            nut.includes("IT") &&
            useAuthStore.getState()?.user?.subscription?.product_type === "only_italy"
          ) {
            newNuts.push(nut);
          }
        }
        strategies[strat.id].nuts = newNuts;
      }
    }
    devDebug(`received the following strategies: `, resp);
    setExistingStrategies(strategies);
    setEditableStrategies(strategies, "tender_strategy");
    compileDefaultStrategies(strategies);
    verifyTabsExist("tender_strategy");
    setNUTSMapStage("initialising");
    verifyMapDataExists();
    verifyCPVDataExists();
    setCPVStage("initial");
    setNUTSMapStage("reloading");
    setStrategiesStatus("loaded");
  };

  const strategiesError = (resp) => {
    devDebug(`strategyService -> strategiesError: ${resp.message}`);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    if (useAuthStore.getState()?.user?.id) {
      if (firstRun) {
        devDebug(
          `strategyService -> first run was true, useAuthStore.getState()?.user?.id is: ${useAuthStore.getState()?.user?.id} `,
        );
        setStrategiesStatus("loading");
        getStrategies(controller);
        setFirstRun(false);
      } else {
        devDebug(
          `strategyService -> first run was NOT true, useAuthStore.getState()?.user?.id is: ${useAuthStore.getState()?.user?.id} `,
        );
      }
    } else {
      setStrategiesStatus("loaded");
    }
    return () => {
      controller.abort();
    };
  }, [useAuthStore.getState()?.user?.email]);

  React.useEffect(() => {
    return () => {
      setFirstRun(false);
    };
  }, []);

  let value = {
    getStrategies,
    existingStrategies,
    strategiesStatus,
    setStrategiesStatus,
    reloadStrategies,
    setFirstRun,
  };

  return strategiesStatus === "loading" ? (
    <ScreenSpinner copy="Fetching your strategies, one moment..." />
  ) : (
    <StrategyContext.Provider value={value}>
      {children}
    </StrategyContext.Provider>
  );
}
