import { useTranslation } from "react-i18next";
import { useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import { returnTenderTitle } from "../summaryResultModal";

export default function TenderTitleComponent() {
  const { t, i18n } = useTranslation();

  const [tender, tenderStatus] = useViewEditTenderStore((state) => [state.tender, state.tenderStatus]);

  return (
    <div className="tc-modal-subheader ms-1">
      {tenderStatus === "loaded" ? (
        <div className="d-flex align-items-center tc-flex-gap-5px overflow-hidden tc-calc-width-hack">
          <div className="fw-bold text-nowrap">{t("Tender Title")}:</div>
          <div className="text-truncate">{returnTenderTitle(tender, i18n.language.slice(0, 2))}</div>
        </div>
      ) : tenderStatus === "error" ? (
        <div className="position-relative">
          <div className="tc-formerror">{t("Error Loading Tender Title...")}</div>
        </div>
      ) : (
        <div>
          {t("Loading Tender Title...")} <div className="tc-spinner-small "></div>
        </div>
      )}
    </div>
  );
}
