import devDebug from "../../../Helpers/debug";
import { updateLocalStorageFilters } from "../../../Helpers/updateLocalStorageFilters";
import { useComponentsStore } from "../../../Stores/componentsStore";
import { useTenderSearchStore } from "../../../Stores/tenderSearch";
import cloneDeep from "lodash/cloneDeep";
import default_mapdata from "../../../Assets/Mapdata/default_mapdata.json";
// import { use } from "chai";

const refreshingTableSuccess = (resp) => {
  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  const setTenders = useTenderSearchStore.getState().setTenders;

  setRefreshingTable(false);
  setTenders(resp);
};

const refreshingTableError = (err, data) => {
  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  setRefreshingTable(false);
  if (data.tableParamRef.current[data.tab].page !== 1) {
    refreshTenderSearchTable(data.auth, data.tabName, true, data.tableParamRef, data.tableSettingsName, data.admin); //true is resetPage
    data.tableParamRef.current[data.tab].page = 1;
    updateLocalStorageFilters(data.tableSettingsName, data.tableParamRef.current);
  }
};

function overWriteAllColumnSelections(tableParamRef, tableSettingsName, tabName) {
  devDebug("Triggered overWriteAllColumnSelections");
  const tab = useTenderSearchStore.getState().tab[tabName];

  let prevtableQueryParams = cloneDeep(tableParamRef.current);

  for (let i = 0; i < prevtableQueryParams.length; i++) {
    prevtableQueryParams[i] = cloneDeep(prevtableQueryParams[tab]);
  }

  tableParamRef.current = prevtableQueryParams;
  updateLocalStorageFilters(tableSettingsName, prevtableQueryParams); //handing this in direct, to make sure it's current
}

export const refreshTenderSearchTable = (auth, tabName, resetPage, tableParamRef, tableSettingsName, admin) => {
  let controller = useTenderSearchStore.getState().tenderSearchController;
  if (controller) {
    controller.abort();
    controller = new AbortController();
    useTenderSearchStore.getState().setTenderSearchController(controller);
  }
  const setRefreshingTable = useTenderSearchStore.getState().setRefreshingTable;
  const tenderStrategies = useTenderSearchStore.getState().tenderStrategies;
  const nutsSearchFromDashboardClick = useComponentsStore.getState().nutsSearchFromDashboardClick;
  const setNutsSearchFromDashboardClick = useComponentsStore.getState().setNutsSearchFromDashboardClick;
  const setRandomReinit = useTenderSearchStore.getState().setRandomReinit;

  const urlPrefix = admin ? "tenders/" : "tender/search/";

  setRandomReinit();
  const tab = useTenderSearchStore.getState().tab[tabName];
  const applyToAllTabs = useTenderSearchStore.getState().applyToAllTabs;

  if (applyToAllTabs) {
    overWriteAllColumnSelections(tableParamRef, tableSettingsName, tabName);
  }

  let filterColumns = tableParamRef.current[tab].filterColumns;
  let searchColumns = tableParamRef.current[tab].searchColumns;
  setRefreshingTable(true);
  let pageNum = tableParamRef.current[tab].page;
  if (resetPage) {
    pageNum = 1;
    tableParamRef.current[tab].page = 1;
    updateLocalStorageFilters(tableSettingsName, tableParamRef.current);
  }

  const no_closing_date = tableParamRef.current[tab].no_closing_date;
  const closing_start = no_closing_date ? "" : tableParamRef.current[tab].closing_date_start;
  const closing_end = no_closing_date ? "" : tableParamRef.current[tab].closing_date_end;
  const confirmed = admin ? `&confirmed=${tableParamRef.current[tab].confirmed}` : "";
  const confirmation_state = admin
    ? `&confirmation_state=${tableParamRef.current[tab].confirmation_state === "all" ? "" : tableParamRef.current[tab].confirmation_state
    }`
    : "";

  const notice_type = admin
    ? `&${tableParamRef.current[tab].notice_type.map((n) => `notice_type=${n.value}`).join("&")}`
    : "";

  if (nutsSearchFromDashboardClick) {
    tableParamRef.current[tab].nut = nutsSearchFromDashboardClick;
    tableParamRef.current[tab].nutDisplay = default_mapdata[nutsSearchFromDashboardClick].name;
    setNutsSearchFromDashboardClick("");
  }

  const use_test_set =
    Object.keys(tenderStrategies)[tab] === "0"
      ? ""
      : tableParamRef.current[tab].use_test_set;

  if (typeof use_test_set === "undefined" || use_test_set === null) {
    tableParamRef.current[tab].use_test_set = false;
  }
  if (typeof tableParamRef.current[tab].hide_disliked === "undefined" || tableParamRef.current[tab].hide_disliked === null) {
    tableParamRef.current[tab].use_test_set = false;
  }
  updateLocalStorageFilters(tableSettingsName, tableParamRef.current);
  const url = `${urlPrefix}?${tab !== 0 ? `strategy_id=${tenderStrategies[tab].id}` : ``}&page=${pageNum}&page_size=${tableParamRef.current[tab].page_size
    }${confirmed}${confirmation_state}${notice_type}&ordering=${tableParamRef.current[tab].orderingURL}&hide_disliked=${tableParamRef.current[tab].hide_disliked}&use_test_set=${use_test_set
    }&min_value=${tableParamRef.current[tab].min_value
    }&max_value=${tableParamRef.current[tab].max_value}&search=${tableParamRef.current[tab].search}&max_value=${tableParamRef.current[tab].max_value
    }&nut=${tableParamRef.current[tab].nut}&columns=${searchColumns
      .filter((n) => !filterColumns.includes(n))
      .join(",")}&closing_after=${closing_start}&closing_before=${closing_end}&publication_after=${tableParamRef.current[tab].publication_date_start
    }&publication_before=${tableParamRef.current[tab].publication_date_end}&no_closing_date=${no_closing_date}`;

  auth.apiService.axiosGET(
    url,
    controller.signal, //signal
    refreshingTableSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving tenders", //error message
    refreshingTableError, //errorFn (gets error back)
    {
      section: "Tender Search -> refreshingTable",
      tableParamRef: tableParamRef,
      auth: auth,
      tab: tab,
      admin: admin,
      tabName: tabName,
      tableSettingsName: tableSettingsName,
    } //data (gets handed back to success fn)
  );
};


const downloadMultiTendersSuccess = (resp, data) => {
  const setTenderArrayToDownload = useTenderSearchStore.getState().setTenderArrayToDownload;
  setTenderArrayToDownload({ currentIteration: data.currentIteration, results: resp.results });
};

const downloadMultiTendersError = (err, data) => {
  const setMultiXLSXStatus = useTenderSearchStore.getState().setMultiXLSXStatus;
  setMultiXLSXStatus("error")
  useTenderSearchStore.getState().setTenderArrayToDownload([]);
  useTenderSearchStore.getState().setTotalIterations(0);
  setTimeout(() => {
    setMultiXLSXStatus("")
  }, 5000)
};


export const downloadMultiTenders = (auth, tabName, tableParamRef, tableSettingsName, admin) => {
  const controller = new AbortController();

  useTenderSearchStore.getState().resetTenderArrayToDownload();
  useTenderSearchStore.getState().setCurrentIteration(0);
  useTenderSearchStore.getState().setMultiXLSXStatus("loading");

  const numTendersToDownload = useTenderSearchStore.getState().numTendersToDownload;
  const tenderStrategies = useTenderSearchStore.getState().tenderStrategies;

  const urlPrefix = admin ? "tenders/" : "tender/search/";

  const tab = useTenderSearchStore.getState().tab[tabName];

  let filterColumns = tableParamRef.current[tab].filterColumns;
  let searchColumns = tableParamRef.current[tab].searchColumns;


  const no_closing_date = tableParamRef.current[tab].no_closing_date;
  const closing_start = no_closing_date ? "" : tableParamRef.current[tab].closing_date_start;
  const closing_end = no_closing_date ? "" : tableParamRef.current[tab].closing_date_end;
  const confirmed = admin ? `&confirmed=${tableParamRef.current[tab].confirmed}` : "";
  const confirmation_state = admin
    ? `&confirmation_state=${tableParamRef.current[tab].confirmation_state === "all" ? "" : tableParamRef.current[tab].confirmation_state
    }`
    : "";

  const notice_type = admin
    ? `&${tableParamRef.current[tab].notice_type.map((n) => `notice_type=${n.value}`).join("&")}`
    : "";

  const use_test_set =
    Object.keys(tenderStrategies)[tab] === "0"
      ? ""
      : tableParamRef.current[tab].use_test_set;

  if (typeof use_test_set === "undefined" || use_test_set === null) {
    tableParamRef.current[tab].use_test_set = false;
  }
  if (typeof tableParamRef.current[tab].hide_disliked === "undefined" || tableParamRef.current[tab].hide_disliked === null) {
    tableParamRef.current[tab].use_test_set = false;
  }
  updateLocalStorageFilters(tableSettingsName, tableParamRef.current);


  let totalIterations = 1

  if (numTendersToDownload > 100) {

    totalIterations = Math.ceil(numTendersToDownload / 100);

  }

  useTenderSearchStore.getState().setTotalIterations(totalIterations);


  for (let i = 1; i <= totalIterations; i++) {
    // note below, page-size is set at 100 always
    const url = `${urlPrefix}?${tab !== 0 ? `strategy_id=${tenderStrategies[tab].id}` : ``}&page=${i}&page_size=100${confirmed}${confirmation_state}${notice_type}&ordering=${tableParamRef.current[tab].orderingURL}&hide_disliked=${tableParamRef.current[tab].hide_disliked}&use_test_set=${use_test_set
      }&min_value=${tableParamRef.current[tab].min_value
      }&max_value=${tableParamRef.current[tab].max_value}&search=${tableParamRef.current[tab].search}&max_value=${tableParamRef.current[tab].max_value
      }&nut=${tableParamRef.current[tab].nut}&columns=${searchColumns
        .filter((n) => !filterColumns.includes(n))
        .join(",")}&closing_after=${closing_start}&closing_before=${closing_end}&publication_after=${tableParamRef.current[tab].publication_date_start
      }&publication_before=${tableParamRef.current[tab].publication_date_end}&no_closing_date=${no_closing_date}`;

    auth.apiService.axiosGET(
      url,
      controller.signal, //signal
      downloadMultiTendersSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error retrieving tenders", //error message
      downloadMultiTendersError, //errorFn (gets error back)
      {
        section: "Tender Search -> downloadMultiTenders",
        totalIterations: totalIterations,
        currentIteration: i,
      } //data (gets handed back to success fn)
    );
  }

};
