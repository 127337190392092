import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
// import i18n from "i18next";
// import cloneDeep from "lodash/cloneDeep";

// define the initial state
const initialState = {
  favourites: [],
  favouritesStatus: "loading",
  favouritesError: "",
  favouriteInProgressIDs: {},
  dislikes: [],
  dislikesStatus: "loading",
  dislikesError: "",
  dislikeInProgressIDs: {},
};

export const useMyTendersStore = createWithEqualityFn(
  immer((set, get) => ({
    ...initialState,
    // ------------------- Dislikes --------------
    setDislikes: (dislikes) =>
      set((state) => {
        state.dislikes = dislikes;
      }),
    setDislikeInProgressIDs: (tenderID) =>
      set((state) => {
        if (state.dislikeInProgressIDs[tenderID]) {
          delete state.dislikeInProgressIDs[tenderID]
        } else {
          state.dislikeInProgressIDs[tenderID] = "loading"
        }

      }),
    setDislikesStatus: (dislikesStatus) =>
      set((state) => {
        state.dislikesStatus = dislikesStatus;
      }),
    setDislikesError: (dislikesError) =>
      set((state) => {
        state.dislikesError = dislikesError;
      }),



    // ------------------- Favourites --------------
    setFavourites: (favourites) =>
      set((state) => {
        state.favourites = favourites;
      }),
    setFavouriteInProgressIDs: (tenderID) =>
      set((state) => {
        if (state.favouriteInProgressIDs[tenderID]) {
          delete state.favouriteInProgressIDs[tenderID]
        } else {
          state.favouriteInProgressIDs[tenderID] = "loading"
        }

      }),
    setFavouritesStatus: (favouritesStatus) =>
      set((state) => {
        state.favouritesStatus = favouritesStatus;
      }),
    setFavouritesError: (favouritesError) =>
      set((state) => {
        state.favouritesError = favouritesError;
      }),
  })),
  shallow
);
