import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/browser";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

if (
  process.env.REACT_APP_ENV === "staging" ||
  process.env.REACT_APP_ENV === "production"
) {
  let env;
  if (process.env.REACT_APP_ENV === "staging") {
    env = "Frontend - Staging";
  }
  if (process.env.REACT_APP_ENV === "production") {
    env = "Frontend - Production";
  }
  Sentry.init({
    dsn: "https://20d291003b23471cb683331e38498cab@sahin.tenderhedge.com/3",
    tracesSampleRate: 0.05,
    environment: env,
  });
}
