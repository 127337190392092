import devDebug from "../../../../../../Helpers/debug";
import { useVademecumStore } from "../../../../../../Stores/vademecumStore";
import { useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import i18n from "../../../../../../i18n";
import { loadTender } from "../../../../2_UtilityFunctions/viewTenderUtilities";

const getQueryLogsSuccess = (resp, data) => {
  useVademecumStore.getState().setQueryHistoryItemLog(resp);
  useVademecumStore.getState().setQueryHistoryItemLogStatus("loaded");
};
const getQueryLogsError = (err, data) => {
  devDebug("There was an error getting query history: ", err);
  useVademecumStore.getState().setQueryHistoryItemLogError(err);
  useVademecumStore.getState().setQueryHistoryItemLogStatus("error");
};

export const getQueryLogs = (auth) => {
  const controller = new AbortController();
  const logID = useVademecumStore.getState().queryHistoryItemLogToBeShown;
  const setQueryHistoryItemLogStatus = useVademecumStore.getState().setQueryHistoryItemLogStatus;
  setQueryHistoryItemLogStatus("loading");
  auth.apiService.axiosGET(
    `ai/query-history/${logID}/logs/`, //endpoint
    controller.signal, //signal
    getQueryLogsSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting this query's logs", //error message
    getQueryLogsError, //errorFn (gets error back)
    {
      auth: auth,
      section: "Summaries -> get query logs",
    } //data (gets handed back to success fn)
  );
};

const getQueryHistorySuccess = (resp, data) => {
  useVademecumStore.getState().setQueryHistory(data.vademecum, data.aiCheckedFieldID, resp.results); //TODO: this needs to set data within the ShowQueryHistoryItems object
  useVademecumStore.getState().setQueryHistoryItemsStatus(data.vademecum, data.aiCheckedFieldID, "loaded");
};
const getQueryHistoryError = (err, data) => {
  devDebug("There was an error getting query history: ", err);
  useVademecumStore.getState().setQueryHistoryItemsStatus(data.vademecum, data.aiCheckedFieldID, "error");
};

export const getQueryHistory = (auth, objectID, aiCheckedFieldID, vademecum) => {
  useVademecumStore.getState().setQueryHistoryItemsStatus(vademecum, aiCheckedFieldID, "loading");
  //TODO: this needs to change to filter by a specific field
  const controller = new AbortController();
  auth.apiService.axiosGET(
    `ai/query-history/?object_id=${objectID}&ai_checked_field_id=${aiCheckedFieldID}&page_size=max`, //endpoint
    controller.signal, //signal
    getQueryHistorySuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting the query history", //error message
    getQueryHistoryError, //errorFn (gets error back)
    {
      vademecum: vademecum,
      aiCheckedFieldID: aiCheckedFieldID,
      auth: auth,
      section: "Summaries -> get query history",
    } //data (gets handed back to success fn)
  );
};

export const returnStatus = (status) => {
  switch (status) {
    case "NEW":
      return i18n.t("New");
    case "IN_PROGRESS":
      return i18n.t("In Progress");
    case "COMPLETED":
      return i18n.t("Completed");
    case "ERROR":
      return i18n.t("Error");
    case "REQUIRES_MANUAL_COMPLETION":
      return i18n.t("Requires Manual Completion");
    default:
      return i18n.t("State not available");
  }
};

export const saveFieldSuccess = (resp, data) => {
  useVademecumStore.getState().setRegenStatus(resp.id, { status: resp.state, vademecumID: data.summaryIDToGET });
  useVademecumStore.getState().setIndividualFieldStatus(resp.id, "saved");
  useViewEditTenderStore.getState().setSummaryFieldValue(resp.id, resp);
  setTimeout(() => {
    useVademecumStore.getState().setIndividualFieldStatus(resp.id, "");
  }, 1000);
};

export const saveFieldError = (err, data) => {
  clearInterval(useVademecumStore.getState().regenInterval);
  useVademecumStore.getState().setRegenStatus(data.id, { status: "ERROR", vademecumID: data.summaryIDToGET });
  useViewEditTenderStore.getState().setSummaryFieldValuesError(err);
  useViewEditTenderStore.getState().setSummaryFieldValuesStatus("error");
  useVademecumStore.getState().setIndividualFieldStatus(data.id, "error");
  setTimeout(() => {
    useVademecumStore.getState().setIndividualFieldStatus(data.id, "");
  }, 1000);
};

export const saveField = (summaryIDToGET, field, auth) => {
  for (let fld of useViewEditTenderStore.getState().summaryFieldValues) {
    if (fld.id === field.id) {
      if (fld.value === field.value) {
        //field.value is e.target.value from fieldValueListItemAdmin
        return;
      } else {
        const controller = new AbortController();
        useVademecumStore.getState().setIndividualFieldStatus(field.id, "saving");

        auth.apiService.axiosPATCH(
          `vademecum/vademecums/${summaryIDToGET}/values/${field.id}/`, //endpoint
          controller.signal, //signal
          saveFieldSuccess, //successFn
          auth.newErrorHandler, //error AlerterFn
          "There was an error saving the summary field", //error message
          saveFieldError, //errorFn (gets error back)
          {
            summaryIDToGET: summaryIDToGET,
            id: field.id,
            payload: field,
            section: "Summary Result Modal -> save field: ",
          } //data (gets handed back to success fn)
        );
      }
    }
  }
};

export const getStatusSuccess = (resp, data) => {
  useVademecumStore.getState().setRegenStatus(data.fieldID, { status: resp.state, vademecumID: data.summaryIDToGET });

  useViewEditTenderStore.getState().setSummaryFieldValue(data.fieldID, resp);

  let keepInterval = false;
  const fields = useVademecumStore.getState().regenStatus;
  for (let fieldID of Object.keys(fields)) {
    if (fields[fieldID].status === "IN_PROGRESS" && fields[fieldID].vademecumID === data.summaryIDToGET) {
      keepInterval = true;
      break;
    }
  }
  if (!keepInterval) {
    clearInterval(useVademecumStore.getState().regenInterval);
    useVademecumStore.getState().setRegenInterval(null);
  }
};

export const getStatusError = (err, data) => {
  useVademecumStore.getState().setRegenStatus(data.fieldID, { status: "ERROR", vademecumID: data.summaryIDToGET });
};

export const getStatus = (summaryIDToGET, field, fieldID, auth) => {
  const controller = new AbortController();
  auth.apiService.axiosGET(
    `vademecum/vademecums/${summaryIDToGET}/values/${fieldID}/`, //endpoint
    controller.signal, //signal
    getStatusSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error checking regen status", //error message
    getStatusError, //errorFn (gets error back)
    {
      auth: auth,
      summaryIDToGET: summaryIDToGET,
      fieldID: fieldID,
      section: "Summaries -> check regen status",
    } //data (gets handed back to success fn)
  );
};

export const checkRegenStatus = (summaryIDToGET, auth) => {
  const fields = useVademecumStore.getState().regenStatus;

  for (let fieldID of Object.keys(fields)) {
    if (fields[fieldID].status === "IN_PROGRESS" && fields[fieldID].vademecumID === summaryIDToGET) {
      getStatus(summaryIDToGET, fields[fieldID], fieldID, auth);
    }
  }
};

export const regenerateFieldSuccess = (resp, data) => {
  useViewEditTenderStore.getState().setSummaryFieldValue(data.fieldID, resp);
  useVademecumStore.getState().setRegenStatus(data.fieldID, {
    status: "IN_PROGRESS",
    vademecumID: data.summaryIDToGET,
  });
  if (!useVademecumStore.getState().regenInterval) {
    const interval = setInterval(() => {
      checkRegenStatus(data.summaryIDToGET, data.auth);
    }, 2000);
    useVademecumStore.getState().setRegenInterval(interval);
  }
};

export const regenerateFieldError = (err, data) => {
  useVademecumStore.getState().setRegenStatus(data.fieldID, {
    status: "ERROR",
    vademecumID: data.summaryIDToGET,
  });
};

export const regenerateField = (fieldID, auth) => {
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;
  const controller = new AbortController();
  useVademecumStore.getState().setRegenStatus(fieldID, { status: "IN_PROGRESS", vademecumID: summaryIDToGET });
  auth.apiService.axiosPATCH(
    `vademecum/vademecums/${summaryIDToGET}/values/${fieldID}/aregenerate/`, //endpoint
    controller.signal, //signal
    regenerateFieldSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error regenerating the field", //error message
    regenerateFieldError, //errorFn (gets error back)
    {
      summaryIDToGET: summaryIDToGET,
      auth: auth,
      fieldID: fieldID,
      section: "Summary Result Modal -> regenerate field: ",
    } //data (gets handed back to success fn)
  );
};

export const getSummarySuccess = (resp, data) => {
  useViewEditTenderStore.getState().setSummary(resp);
  useViewEditTenderStore.getState().setSummaryStatus("loaded");
  const tenderID = useViewEditTenderStore.getState().tender.id;
  loadTender(data.auth, tenderID);
};

export const getSummaryError = (err) => {
  useViewEditTenderStore.getState().setSummaryError(err);
  useViewEditTenderStore.getState().setSummaryStatus("error");
};

export const getSummary = (controller, auth) => {
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;
  auth.apiService.axiosGET(
    `vademecum/vademecums/${summaryIDToGET}/`, //endpoint
    controller.signal, //signal
    getSummarySuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving the summary", //error message
    getSummaryError, //errorFn (gets error back)
    { auth: auth, section: "Summary Results Modal -> getSummary: " } //data (gets handed back to success fn)
  );
};

export const getSummaryFieldValuesSuccess = (resp, data) => {
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;

  useViewEditTenderStore.getState().setSummaryFieldValues(resp.results);
  // useViewEditTenderStore.getState().setSummaryFieldValues(vals.results);

  let startInterval = false;

  for (let field of resp.results) {
    useVademecumStore.getState().setRegenStatus(field.id, {
      status: field.state,
      vademecumID: summaryIDToGET,
    });
    if (field.state === "IN_PROGRESS") {
      startInterval = true;
    }
  }

  if (startInterval) {
    devDebug("starting interval, as in_progress items found");
    if (!useVademecumStore.getState().regenInterval) {
      const interval = setInterval(() => {
        checkRegenStatus(summaryIDToGET, data.auth);
      }, 2000);
      useVademecumStore.getState().setRegenInterval(interval);
    }
  } else {
    devDebug("there was no need to start the interval, clearing any existing intervals");
    clearInterval(useVademecumStore.getState().regenInterval);
  }

  useViewEditTenderStore.getState().setSummaryFieldValuesStatus("loaded");
};

export const getSummaryFieldValuesError = (err) => {
  useViewEditTenderStore.getState().setSummaryFieldValuesError(err);
  useViewEditTenderStore.getState().setSummaryFieldValuesStatus("error");
};

export const getSummaryFieldValues = (controller, auth) => {
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;

  auth.apiService.axiosGET(
    `vademecum/vademecums/${summaryIDToGET}/values/?page_size=max`, //endpoint
    controller.signal, //signal
    getSummaryFieldValuesSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an uerror retrieving the summary field values", //error message
    getSummaryFieldValuesError, //errorFn (gets error back)
    { auth: auth, section: "Summary Results Modal -> getSummaryFieldValues: " } //data (gets handed back to success fn)
  );
};
