import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  page: 1,
  search: "",
  pageSize: 10,
  totalPages: null,
  pageInput: false,
  usersList: [],
  hideColumns: [],
};

export const useListUsersStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      selectColumn: (col) =>
        set((state) => {
          if (state.hideColumns.includes(col)) {
            state.hideColumns = state.hideColumns.filter((c) => c !== col);
          } else {
            state.hideColumns = [...state.hideColumns, col];
          }
        }),
      setPage: (payload) =>
        set((state) => {
          state.page = payload;
        }),
      setSearch: (payload) =>
        set((state) => {
          state.search = payload;
        }),
      setPageSize: (payload) =>
        set((state) => {
          state.pageSize = payload;
        }),
      setTotalPages: (payload) =>
        set((state) => {
          state.totalPages = payload;
        }),
      setPageInput: (payload) =>
        set((state) => {
          state.pageInput = payload;
        }),
      setUsersList: (payload) =>
        set((state) => {
          state.usersList = payload;
        }),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated"].includes(key)
          )
        ),
      name: process.env.REACT_APP_LIST_USERS_STORE_KEY,
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tab store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("tab store -> an error happened during hydration", error);
          } else {
            // devDebug("tab store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
