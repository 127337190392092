import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
import devDebug from "../Helpers/debug";
import default_mapdata from "../Assets/Mapdata/default_mapdata.json";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import lvl0_nuts from "../Assets/Mapdata/lvl0_nuts.json";
import lvl1_nuts from "../Assets/Mapdata/lvl1_nuts.json";
import lvl2_nuts from "../Assets/Mapdata/lvl2_nuts.json";
import lvl3_nuts from "../Assets/Mapdata/lvl3_nuts.json";
import dayjs from "dayjs";
import nutsEU from "../Assets/Mapdata/nuts_20M_4326.json";
import { useTabStore } from "./tabStore";
import { useTenderStrategyStore } from "./tenderStrategyStore";
// import cloneDeep from "lodash/cloneDeep";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const defaultMapState = {
  level: 0,
  displayOption: "tender_count",
  refreshMap: true,
};

// define the initial state
// where 'default' instead of 'all' is used, states without tabs can exist
// this should mirror 'addEnabled = true' in strategyConstants.js
const initialState = {
  _hasHydrated: false,
  mapData: {
    tender_strategy: { default: default_mapdata },
    dashboard_logged_in: { all: default_mapdata },
    dashboard_anonymous: { anonymous: default_mapdata },
    edit_user: { default: default_mapdata },
  },
  NUTSMapStage: "reloading",
  mapState: {
    tender_strategy: { default: defaultMapState },
    dashboard_logged_in: { all: defaultMapState },
    dashboard_anonymous: {
      anonymous: defaultMapState,
    },
    edit_user: { default: defaultMapState },
  },
};

export const useMapsStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      // ------------------- INIT ----------------------
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      verifyMapDataExists: () =>
        set((state) => {
          let keys = {};
          const allStrategies = {
            ...useTenderStrategyStore.getState().editableStrategies
              .tender_strategy,
            ...useTenderStrategyStore.getState().editableStrategies.edit_user,
          };
          for (let section of Object.keys(state.mapData)) {
            for (let id of Object.keys(allStrategies)) {
              if (!Object.keys(state.mapData[section]).includes(id)) {
                state.mapData[section][id] = default_mapdata;
                state.mapState[section][id] = defaultMapState;
              }
            }
          }
          for (let section of Object.keys(state.mapData)) {
            for (let id of Object.keys(state.mapData[section])) {
              if (!Object.keys(allStrategies).includes(id)) {
                if (!keys[section]) {
                  keys[section] = {};
                }
                if (id !== "default" && id !== "all" && id !== "anonymous")
                  keys[section][id] = true;
              }
            }
          }
          for (let section of Object.keys(keys)) {
            for (let key of Object.keys(keys[section])) {
              delete state.mapData[section][key];
              delete state.mapState[section][key];
            }
          }
          // devDebug(
          //   `verifyMapDataExists -> These keys don't exist anymore and should be deleted from mapData and mapState: ${JSON.stringify(
          //     keys
          //   )}`
          // );
        }),
      setNUTSMapStage: (payload) =>
        set((state) => {
          state.NUTSMapStage = payload;
        }),
      // ------------------- MAPS STATES ----------------------
      setMetric: (payload) =>
        set((state) => {
          state.metric = payload;
        }),
      setDisplayOption: (value, section) =>
        set((state) => {
          const id = useTabStore.getState().currentTab[section];
          state.mapState[section][id].displayOption = value;
        }),
      setLevel: (value, section) =>
        set((state) => {
          const id = useTabStore.getState().currentTab[section];
          state.mapState[section][id].level = value;
        }),
      setRefreshLayer: (payload) =>
        set((state) => {
          state.refreshLayer = payload;
        }),
      addDefaultMapState: (section, id) =>
        set((state) => {
          state.mapState[section][id] = defaultMapState;
        }),
      setRefreshMap: (section, id, payload) =>
        set((state) => {
          state.mapState[section][id].refreshMap = payload;
        }),
      transferMapState: (section, oldID, NewID, payload) =>
        set((state) => {
          devDebug(
            `transferMapState -> removing ${oldID} from ${section} and creating ${NewID}`
          );
          state.mapState[section][NewID] = payload;
          delete state.mapState[section][oldID];
        }),
      // ------------------- MAPS DATA ------------------------
      setMapData: (payload, section, id) =>
        set((state) => {
          state.mapData[section][id] = createMapData(payload);
          state.NUTSMapStage = "loaded";
          state.mapState[section][id].refreshMap = false;
        }),
      addDefaultMapData: (section, id) =>
        set((state) => {
          state.mapData[section][id] = default_mapdata;
        }),
      transferMapData: (section, oldID, NewID, payload) =>
        set((state) => {
          devDebug(
            `transferMapData -> removing ${oldID} from ${section} and creating ${NewID}`
          );
          state.mapData[section][NewID] = payload;
          delete state.mapData[section][oldID];
        }),
      // ------------------- RESET ------------------------
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated", "NUTSMapStage"].includes(key)
          )
        ),
      name: "maps",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("maps store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("maps store -> an error happened during hydration", error);
          } else {
            // devDebug("maps store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);

function createMapData(resp) {
  var now = dayjs().format();
  let displayOptions = ["total_price", "avg_price", "tender_count"];
  let newMapData = {};

  newMapData = {};
  newMapData.updated = now;
  newMapData.default = false;
  newMapData.maxValues = {};
  newMapData.maxValues.avg_price = [0, 0, 0, 0];
  newMapData.maxValues.total_price = [0, 0, 0, 0];
  newMapData.maxValues.tender_count = [0, 0, 0, 0];
  newMapData.brackets = {};
  newMapData.brackets.avg_price = [[], [], [], []];
  newMapData.brackets.total_price = [[], [], [], []];
  newMapData.brackets.tender_count = [[], [], [], []];
  for (let data of resp) {
    newMapData[data.code] = {
      currency: data.currency,
      avg_price: parseInt(data.avg_price),
      total_price: parseInt(data.total_price),
      tender_count: data.tender_count,
      name: getNUTName(data.code),
    };
    for (let opt of displayOptions) {
      //nuts data from server is always level 3
      if (newMapData.maxValues[opt][3] < newMapData[data.code][opt]) {
        newMapData.maxValues[opt][3] = newMapData[data.code][opt];
      }
    }
  }

  let tempCodes = [];
  let missingCodes = [];
  for (let data of resp) {
    tempCodes.push(data.code);
  }
  for (let id of lvl3_nuts) {
    if (!tempCodes.includes(id)) {
      missingCodes.push(id);
    }
  }

  for (let code of missingCodes) {
    newMapData[code] = {
      currency: "EUR",
      avg_price: 0,
      total_price: 0,
      tender_count: 0,
      name: getNUTName(code),
    };
  }

  assignObjectAndMaxValues(newMapData, lvl0_nuts, 0, displayOptions);
  assignObjectAndMaxValues(newMapData, lvl1_nuts, 1, displayOptions);
  assignObjectAndMaxValues(newMapData, lvl2_nuts, 2, displayOptions);
  for (let opt of displayOptions) {
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 20; j++) {
        newMapData.brackets[opt][i].push(
          parseInt((newMapData.maxValues[opt][i] / 20) * j)
        );
      }
    }
  }
  return newMapData;
}

function assignObjectAndMaxValues(newMapData, nutsSet, i, displayOptions) {
  for (let nutsID of nutsSet) {
    //set of level 0, 1, 2 NUTS IDs
    newMapData[nutsID] = {
      currency: "",
      avg_price: 0,
      total_price: 0,
      tender_count: 0,
      name: getNUTName(nutsID),
    };
    let codes = [];
    for (let key of Object.keys(newMapData)) {
      if (key.includes(nutsID) && !codes.includes(key)) {
        codes.push(nutsID);
        newMapData[nutsID].tender_count += newMapData[key].tender_count;
        newMapData[nutsID].avg_price += newMapData[key].avg_price;
        newMapData[nutsID].total_price += newMapData[key].total_price;
      }
      for (let opt of displayOptions) {
        if (newMapData.maxValues[opt][i] < newMapData[nutsID][opt]) {
          newMapData.maxValues[opt][i] = newMapData[nutsID][opt];
        }
      }
    }
  }
}

function getNUTName(code) {
  for (let feature of nutsEU.features) {
    if (feature.properties.NUTS_ID === code) {
      return feature.properties.NUTS_NAME;
    }
  }
}
