import React from "react";
import devDebug from "../../Helpers/debug";
import { ErrorBoundary } from "react-error-boundary";
import { MainAppErrorComponent } from "../../Components/errorComponent";
import MyTendersPage from "./myTendersPage";

export default function MyTendersErrorBoundary() {
  // const [resetState] = useListUsersStore((state) => [state.resetState]);

  return (
    <ErrorBoundary
      FallbackComponent={MainAppErrorComponent}
      onReset={(details) => {
        devDebug("List users -> error boundary reset: ", details);
        // resetState();
      }}
    >
      <MyTendersPage />
    </ErrorBoundary>
  );
}
