import React from "react";
import i18n from "../../i18n";
import { Helmet } from "react-helmet";

export default function ReusableHelmet({ section, tabTitle, description, pageTitle, socialImage }) {
  //translation is handled in individual component instances
  return (
    <Helmet>
      <title>{`${section} | ${tabTitle}`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={socialImage} />
      <html lang={i18n.language.slice(0, 2)} />
    </Helmet>
  );
}
