import React from "react";
import { useAuth } from "../Services/auth";
export default function Alerter() {
  const auth = useAuth();
  return (
    <div
      className={
        auth.showAlert
          ? "th-alert alert_visible " + auth?.alert.type
          : "th-alert alert_hidden " + auth?.alert.type
      }
    >
      {auth?.alert.message}
    </div>
  );
}
