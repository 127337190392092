import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useComponentsStore } from "../../Stores/componentsStore";
// import devDebug from "../Helpers/debug";
export default function TendersDropdown({ sidebar, ctx }) {
  const { t } = useTranslation();
  const [awardsDropDownOpen] = useComponentsStore((state) => [
    state.awardsDropDownOpen,
  ]);
  const [menu, setMenu] = React.useState(awardsDropDownOpen);
  const toggle = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    //devDebug("awards dropdown open has changed");
    setMenu(awardsDropDownOpen);
  }, [awardsDropDownOpen]);

  return (
    <Dropdown
      key={"dropdown"}
      isOpen={menu}
      toggle={toggle}
      className={`${sidebar ? "mobile-sidebar-nested-dropdown" : ""} ${
        menu ? "tc-nested-menu-open" : "tc-nested-menu-closed"
      } d-inline-block`}
    >
      <DropdownToggle
        tag="a"
        className={`menu-item ${menu ? "tc-pointer-events-none" : ""} `}
      >
        {t("Tenders")}
        <span>
          <Icon
            path={mdiChevronDown}
            className="d-xl-inline-block profile_menu_chevron"
            title={t("Tenders Dropdown")}
            size={0.7}
          />
        </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <div
          className={`${
            sidebar ? "pt-2" : ""
          } d-flex flex-column p-3 tc-flex-gap-10px`}
        >
          <DropdownItem>
            <NavLink
              onClick={() => {
                ctx.toggleMenu(false);
                toggle();
              }}
              className={`menu-item nomargin  ${
                sidebar ? "nested-mobile-menu-item menu-item-sidebar" : ""
              }`}
              to="/search"
            >
              {t("Search Tenders")}
            </NavLink>
          </DropdownItem>
          <DropdownItem>
            <NavLink
              onClick={() => {
                ctx.toggleMenu(false);
                toggle();
              }}
              to="/my_tenders"
              className={`menu-item nomargin  ${
                sidebar ? "nested-mobile-menu-item menu-item-sidebar" : ""
              }`}
            >
              {t("My Tenders")}
            </NavLink>
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}
