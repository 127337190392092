import React from "react";
import { useMediaQuery } from "react-responsive";
import { useComponentsStore } from "../../Stores/componentsStore";

export function useColorScheme() {
  const colourSchemeKey = `${process.env.REACT_APP_COLOR_SCHEME_KEY}_${process.env.REACT_APP_VERSION}`;
  const [isDark, setIsDark] = React.useState(
    localStorage.getItem(colourSchemeKey) || "light"
  );
  const [setDark] = useComponentsStore((state) => [state.setIsDark]);
  React.useEffect(() => {
    localStorage.setItem(colourSchemeKey, isDark);
    setDark(isDark === "light" ? false : true);
  }, [isDark, setDark, colourSchemeKey]);

  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );
  const value = React.useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );
  React.useEffect(() => {
    let isSubscribed = true;
    if (value === "dark") {
      if (isSubscribed) {
        document.body.classList.add("dark");
      }
    } else {
      if (isSubscribed) {
        document.body.classList.remove("dark");
      }
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  }, [value]);

  return {
    isDark: value,
    setIsDark: setIsDark,
  };
}
