import { mdiPostOutline } from "@mdi/js";
import Icon from "@mdi/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useVademecumStore } from "../../../../../../Stores/vademecumStore";

export const QueryHistoryItem = ({ item }) => {
  const { t } = useTranslation();

  const [setQueryHistoryItemLogToBeShown, setQueryHistoryItemLogModalVisible, setQueryHistoryItemLogName] =
    useVademecumStore((state) => [
      state.setQueryHistoryItemLogToBeShown,
      state.setQueryHistoryItemLogModalVisible,
      state.setQueryHistoryItemLogName,
    ]);

  return (
    <div className="d-flex flex-column tc-flex-gap-5px">
      <div className="d-flex tc-flex-gap-5px align-items-center">
        <div className="fw-bold tc-purple-text">{dayjs(item.created_at).format("DD/MM/YYYY - HH:mm")}</div>
        <Icon
          path={mdiPostOutline}
          className="tc_grow_on_hover_icon cursor-pointer"
          title={t("View Query History Item Log")}
          size={1}
          onClick={() => {
            setQueryHistoryItemLogName(item.query_key);
            setQueryHistoryItemLogToBeShown(item.id);
            setQueryHistoryItemLogModalVisible(true);
          }} //vademecum,queryKey,show
        />
      </div>
      <div className="d-flex tc-flex-gap-5px">
        <div className="fw-bold">{`${t("Prompt")}: `}</div>
        <div className="">{item.prompt}</div>
      </div>
      <div className="d-flex tc-flex-gap-5px">
        <div className="fw-bold">{`${t("Result")}: `}</div>
        <div className="">{`${item.result.slice(0, 150)}${item.result.length > 150 ? "..." : t("N/A")}`}</div>
      </div>
      <hr />
    </div>
  );
};
