import React from "react";
import { useExcelJS } from "react-use-exceljs";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useVademecumStore } from "../../../../../Stores/vademecumStore";
import { useViewEditTenderStore } from "../../../../../Stores/viewEditTender";
import summary_pdf_shapes from "../../../../../Assets/Images/summary_pdf_shapes.png";
import tenders_club_logo_and_tagline from "../../../../../Assets/Images/tenders_club_logo_and_tagline_result.png";
import appalti_logo from "../../../../../Assets/Images/appalti_logo.png";
const data = [];
const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const cols = 10;
const normalLineHeight = 30;
const headingLineHeight = 100;
const lotsTableStartPosition = 2;
const footerStartCol = 0;
const headerStartCol = 0;
const paddingBetweenValsAndTable = 30;
const paddingToFooter = 60;

const addTitle = (worksheet, tenderTitle) => {
  const firstRow = worksheet.addRow();
  firstRow.height = 100;
  const secondRow = worksheet.addRow();
  worksheet.mergeCells(2, 2, 2, cols + 1);
  const headingCell = worksheet.getCell(`B2`);
  headingCell.value = tenderTitle;
  headingCell.font = {
    name: "Oswald",
    family: 2,
    size: 24,
    bold: true,
  };
  headingCell.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  autoHeightHeading(secondRow, headingLineHeight);
};

const setFirstCellValue = (cell, value, last) => {
  cell.value = value;
  cell.font = {
    name: "Oswald",
    family: 2,
    size: 12,
    bold: true,
  };
  cell.alignment = {
    vertical: "middle",
    horizontal: "right",
    wrapText: true,
  };
  if (last) {
    cell.border = {
      right: { style: "thin", color: { argb: "FF000000" } },
    };
  } else {
    cell.border = {
      bottom: { style: "thin", color: { argb: "FF000000" } },
      right: { style: "thin", color: { argb: "FF000000" } },
    };
  }
};

const setSecondCellValue = (cell, value, last) => {
  cell.value = value;

  cell.alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  if (last) {
    cell.border = {
      left: { style: "thin", color: { argb: "FF000000" } },
    };
  } else {
    cell.border = {
      left: { style: "thin", color: { argb: "FF000000" } },
      bottom: { style: "thin", color: { argb: "FF000000" } },
    };
  }
};

export default function XLSXDownloadButton({ tenderTitle }) {
  const [XLSXData] = useVademecumStore((state) => [state.XLSXData]);
  const summaryNameToGET = useViewEditTenderStore.getState().summaryNameToGET;
  const { t } = useTranslation();

  let excel = useExcelJS({
    filename: `${summaryNameToGET.replace(
      /[ &/\\#,+()$~%.'":*?<>{}]/g,
      "-"
    )}.xlsx`,
    intercept: (workbook) => {
      workbook.creator = "Tenders Club";
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet("Summary", {
        pageSetup: { paperSize: 9, orientation: "landscape" },
        views: [{ showGridLines: false }],
      });
      addTitle(worksheet, tenderTitle);
      worksheet.addRow();
      const middle = cols / 2;
      for (let i = 0; i < XLSXData.nonLotsVals.length; i++) {
        let newRow = worksheet.addRow();
        newRow.font = { name: "Montserrat", family: 2, size: 10 };
        let rowNumber = newRow._number;
        worksheet.mergeCells(
          rowNumber,
          headerStartCol + 2,
          rowNumber,
          middle - 1
        );
        worksheet.mergeCells(rowNumber, middle, rowNumber, cols + 1);
        const cell1 = worksheet.getCell(
          `${letters[headerStartCol + 1]}${rowNumber}`
        );
        const cell2 = worksheet.getCell(`${letters[cols - 1]}${rowNumber}`);
        setFirstCellValue(
          cell1,
          XLSXData.nonLotsVals[i][0],
          i === XLSXData.nonLotsVals.length - 1
        );
        setSecondCellValue(
          cell2,
          XLSXData.nonLotsVals[i][1],
          i === XLSXData.nonLotsVals.length - 1
        );
        autoHeight(newRow, normalLineHeight);
      }
      worksheet.addRow();

      // XLSXData.nonLotsVals.map((row) => worksheet.addRow(row));
      if (XLSXData.lotsVals && XLSXData.lotsVals.length > 1) {
        const paddingRow = worksheet.addRow();
        paddingRow.height = paddingBetweenValsAndTable;
        let firstLotsRow = worksheet.addRow();
        firstLotsRow.font = {
          name: "Montserrat",
          family: 2,
          size: 10,
          bold: true,
          wrapText: true,
        };
        firstLotsRow.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        autoHeightLots(firstLotsRow, normalLineHeight);

        mergeCellsForTable(
          worksheet,
          firstLotsRow._number,
          XLSXData.lotsVals[0]
        );

        for (let i = 1; i < XLSXData.lotsVals.length; i++) {
          let newRow = worksheet.addRow();
          newRow.alignment = {
            vertical: "middle",
            horizontal: "left",
            wrapText: true,
          };
          newRow.font = {
            name: "Montserrat",
            family: 2,
            size: 10,
            bold: false,
            wrapText: true,
          };
          mergeCellsForTable(worksheet, newRow._number, XLSXData.lotsVals[i]);
          autoHeightLots(newRow, normalLineHeight);
        }
      }

      const paddingRow = worksheet.addRow();
      paddingRow.height = paddingToFooter;
      const lastRow = worksheet.addRow();
      lastRow.alignment = {
        vertical: "middle",
        horizontal: "middle",
        wrapText: false,
      };
      const footerRow = lastRow._number - 1;

      addHeader(
        workbook,
        worksheet,
        cols,
        summary_pdf_shapes,
        tenders_club_logo_and_tagline
      );

      addFooter(workbook, worksheet, footerRow, appalti_logo);
    },
    worksheets: [],
  });

  const exportXLSX = () => {
    excel.download(data);
  };

  return (
    <Button
      className="tc-full-width-small"
      onClick={() => exportXLSX()}
      type="button"
      color="secondary"
    >
      {t("Download .xlsx")}
    </Button>
  );
}

const addHeader = (
  workbook,
  worksheet,
  cols,
  summary_pdf_shapes,
  tenders_club_logo_and_tagline
) => {
  let topLeftImg = summary_pdf_shapes;
  let topRightImg = tenders_club_logo_and_tagline;

  const topLeftShapes = workbook.addImage({
    base64: topLeftImg,
    extension: "png",
  });
  const topRightLogo = workbook.addImage({
    base64: topRightImg,
    extension: "jpg",
  });

  worksheet.addImage(topLeftShapes, {
    tl: { col: headerStartCol, row: 0 },
    ext: { width: 170, height: 100 },
  });

  worksheet.addImage(topRightLogo, {
    tl: { col: cols - 2, row: 0 },
    ext: { width: 300, height: 95 },
  });
};

const addFooter = (workbook, worksheet, footerRow, appalti_logo) => {
  let bottomLeftImg = appalti_logo;

  const bottomLeftAppaltiLogo = workbook.addImage({
    base64: bottomLeftImg,
    extension: "png",
  });

  worksheet.addImage(bottomLeftAppaltiLogo, {
    tl: { col: footerStartCol, row: footerRow },
    ext: { width: 60, height: 75 },
  });

  const footerCell1 = worksheet.getCell(
    `${letters[footerStartCol + 3]}${footerRow + 2}`
  );
  footerCell1.value = "Ufficio Appalti Srl";
  footerCell1.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: false,
  };
  const footerCell2 = worksheet.getCell(
    `${letters[footerStartCol + 6]}${footerRow + 2}`
  );
  footerCell2.value = "Milano. +39 02 92853681";
  footerCell2.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: false,
  };
  const footerCell3 = worksheet.getCell(
    `${letters[footerStartCol + 10]}${footerRow + 2}`
  );
  footerCell3.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: false,
  };
  footerCell3.value = {
    text: "info@tenders.club",
    hyperlink: "mailto:info@tenders.club",
    tooltip: "info@tenders.club",
  };
  const footerCell4 = worksheet.getCell(
    `${letters[footerStartCol + 10]}${footerRow + 3}`
  );
  footerCell4.alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: false,
  };
  footerCell4.value = {
    text: "info@ufficioappalti.com",
    hyperlink: "mailto:info@ufficioappalti.com",
    tooltip: "info@ufficioappalti.com",
  };
};

const autoHeightHeading = (row, lineHeight) => {
  let maxLine = 1;
  row.eachCell((cell) => {
    maxLine = Math.max(cell.value.length / 60, maxLine);
  });

  row.height = lineHeight * maxLine;
};

const autoHeight = (row, lineHeight) => {
  let maxLine = 1;
  row.eachCell((cell) => {
    maxLine = Math.max(cell.value.length / 70, maxLine);
  });
  if (lineHeight * maxLine > 200) {
    row.height = 200;
  } else {
    row.height = lineHeight * maxLine;
  }
};

const autoHeightLots = (row, lineHeight) => {
  let maxLine = 1;
  row.eachCell((cell) => {
    maxLine = Math.max(cell.value.length / 17, maxLine);
  });
  row.height = lineHeight * maxLine;
};

const mergeCellsForTable = (worksheet, rowNumber, data) => {
  let numCells = data.length;
  let j = 0;
  for (
    let i = lotsTableStartPosition;
    i < numCells + (lotsTableStartPosition - 1);
    i += 2
  ) {
    worksheet.mergeCells(rowNumber, i, rowNumber, i + 1);
    const cell = worksheet.getCell(`${letters[i]}${rowNumber}`);
    cell.value = data[j];
    cell.border = {
      top: { style: "thin", color: { argb: "FF000000" } },
      left: { style: "thin", color: { argb: "FF000000" } },
      bottom: { style: "thin", color: { argb: "FF000000" } },
      right: { style: "thin", color: { argb: "FF000000" } },
    };
    j++;
  }
};
