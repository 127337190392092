import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const tenderSearchTabAdminKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_ADMIN_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTabKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;
const companiesSearchTabKey = `${process.env.REACT_APP_COMPANIES_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderAwardsSearchTabKey = `${process.env.REACT_APP_AWARDS_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;

let tab = {};

tab[tenderSearchTabAdminKey] = 0;
tab[tenderSearchTabKey] = 0;
tab[companiesSearchTabKey] = 0;
tab[tenderAwardsSearchTabKey] = 0;

const initialState = {
  tab: tab,
  tenderStrategies: [],
  storeAwards: [],
  tenderStrategiesLoaded: false,
  applyToAllTabs: false,
  awardInfoID: null,
  showAwardInfoModal: false,
  inputCPV: "",
  hideListCPVSearch: true,
  award: null,
  awardStatus: "loading",
  awardError: "",
};

export const useTenderAwardsSearchStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      setAward: (award) =>
        set((state) => {
          state.award = award;
        }),
      setAwardStatus: (awardStatus) =>
        set((state) => {
          state.awardStatus = awardStatus;
        }),
      setAwardError: (awardError) =>
        set((state) => {
          state.awardError = awardError;
        }),
      setInputCPV: (payload) =>
        set((state) => {
          state.inputCPV = payload;
        }),
      setHideListCPVSearch: (payload) =>
        set((state) => {
          state.hideListCPVSearch = payload;
        }),
      setAwardInfoID: (payload) =>
        set((state) => {
          state.awardInfoID = payload;
        }),
      setShowAwardInfoModal: (payload) =>
        set((state) => {
          state.showAwardInfoModal = payload;
        }),
      setStoreAwards: (payload) =>
        set((state) => {
          let awrds = {};
          for (let award of payload.results) {
            awrds[award.id] = award;
          }
          state.storeAwards = awrds;
        }),
      settableQueryParams: (payload) =>
        set((state) => {
          state.tableQueryParams = payload;
        }),
      setTab: (payload, section) =>
        set((state) => {
          state.tab[section] = payload;
        }),
      setApplyToAllTabs: (payload) =>
        set((state) => {
          state.applyToAllTabs = payload;
        }),
      setTenderStrategies: (tabName, payload) =>
        set((state) => {
          state.tenderStrategies = payload;
          const actualTab = { ...state.tab };
          if (actualTab[tabName] >= state.tenderStrategies.length) {
            state.tab[tabName] = 0;
          }
        }),
      setTenderStrategiesLoaded: (payload) =>
        set((state) => {
          state.tenderStrategiesLoaded = payload;
        }),
      resetState: () => set(initialState),
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "_hasHydrated",
                "tenderStrategies",
                "tenderStrategiesLoaded",
                "awardStatus",
                "awardInfoID",
                "showAwardInfoModal",
              ].includes(key)
          )
        ),
      name: "tenderAwardsSearch1",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tender awards search store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "tender awards search store -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug(
            //   "tender awards search store -> hydration finished, state: ",
            //   state
            // );
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
