import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { defaultTabs } from "../Services/strategies/strategyConstants";
// import devDebug from "../Helpers/debug";

// define the initial state
const initialState = {
  _hasHydrated: false,
  currentTab: defaultTabs,
  firstTime: false,
  accessToken: null,
  refreshToken: null,
  reloadedOnce: false,
  user: null,
  reloadAfterStripe: false,
  jwtRefreshed: false,
  strategiesNeverRequested: true,
};

export const useAuthStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (hasHydrated) => {
        set((state) => {
          state._hasHydrated = hasHydrated;
        })
      },
      setStrategiesNeverRequested: (strategiesNeverRequested) => {
        set((state) => {
          state.strategiesNeverRequested = strategiesNeverRequested;
        })
      },
      setJWTRefreshed: (jwtRefreshed) => {
        set((state) => {
          state.jwtRefreshed = jwtRefreshed;
        })
      },
      setReloadAfterStripe: (reloadAfterStripe) => {
        set((state) => {
          state.reloadAfterStripe = reloadAfterStripe;
        })
      },
      setUser: (user) => {
        set((state) => {
          state.user = user;
        })
      },
      setReloadedOnce: (reloadedOnce) => {
        set((state) => {
          state.reloadedOnce = reloadedOnce;
        })
      },
      setFirstTime: (firstTime) => {
        set((state) => {
          state.firstTime = firstTime;
        })
      },
      setAccessToken: (accessToken) => {
        set((state) => {
          state.accessToken = accessToken;
        })
      },
      setRefreshToken: (refreshToken) => {
        set((state) => {
          state.refreshToken = refreshToken;
        })
      },
      setTabID: (section, id) =>
        set((state) => {
          state.currentTab[section] = `${id}`;
        }),
      resetState: () => {
        window.location.reload();
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated", "jwtRefreshed"].includes(key)
          )
        ),
      name: "authStore",
      version: process.env.REACT_APP_LOGIN_DETAILS_KEY,
      onRehydrateStorage: (state) => {
        // devDebug("authStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("authStore -> an error happened during hydration", error);
          } else {
            // devDebug("authStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
