import React from "react";
import { useNavigate } from "react-router-dom";
import devDebug from "../Helpers/debug";
export default function NoMatch() {
  devDebug("Route did not match, redirecting...");
  const navigate = useNavigate();
  React.useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      devDebug("No Match -> navigating to dashboard");
      navigate("/dashboard");
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  });
  return <div></div>;
}
