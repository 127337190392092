import React from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useVademecumStore } from "../../Stores/vademecumStore";

export default function CreatedAtDateRangeBox() {
  const { t } = useTranslation();

  const [created_at_after, setCreatedAtAfter, created_at_before, setCreatedAtBefore] = useVademecumStore((state) => [
    state.created_at_after,
    state.setCreatedAtAfter,
    state.created_at_before,
    state.setCreatedAtBefore,
  ]);

  return (
    <div className="tc-tender-table-filter-box tc-tender-table-filter-box-2">
      <h2 className="tc-tender-table-filter-box-heading tc-text-muted">{t("Created at")}:</h2>
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val2">{t("After")}:</div>
      <Input
        name="created_at_after"
        className={`form-control th-tender-table-filters-input th-edit-tenders-input-chrome-fix tc-tender-table-filter-box-val3 ${
          created_at_after ? "" : "empty-placeholder-color"
        }`}
        placeholder={t("Created At After")}
        type="date"
        onChange={(e) => setCreatedAtAfter(e.currentTarget.value)}
        defaultValue={created_at_after}
      />
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val4">{t("Before")}:</div>
      <Input
        name="created_at_before"
        className={`form-control th-tender-table-filters-input th-edit-tenders-input-chrome-fix tc-tender-table-filter-box-val5 ${
          created_at_before ? "" : "empty-placeholder-color"
        }`}
        placeholder={t("Created At Before")}
        type="date"
        onChange={(e) => setCreatedAtBefore(e.currentTarget.value)}
        defaultValue={created_at_before}
      />
    </div>
  );
}
