import { useViewEditTenderStore } from "../../../Stores/viewEditTender";
import devDebug from "../../../Helpers/debug";
import { useVademecumStore } from "../../../Stores/vademecumStore";
import cloneDeep from "lodash/cloneDeep";
import i18n from "../../../i18n";
import { useTaskManagementStore } from "../../../Stores/taskManagementStore";
import { refreshTenderSearchTable } from "../../Protected - Tender Search/1_Utility/tenderSearchUtility";
import { useTenderSearchStore } from "../../../Stores/tenderSearch";
import dayjs from "dayjs";
import { getQueryHistoryEditTender } from "../InfoTab/1_Utility/utility";
import { useAuthStore } from "../../../Stores/authStore";

export const loadTenderSuccess = (resp) => {
  useViewEditTenderStore.getState().setTender(resp);
  useViewEditTenderStore.getState().setTenderStatus("loaded");
};

export const loadTenderError = () => {
  useViewEditTenderStore.getState().setTenderError(true);
  useViewEditTenderStore.getState().setTenderStatus("error");
};
export const loadTender = (auth, id) => {
  if (id !== 0 && id !== "0") {
    //this is needed because when viewing a vademecum after adding a tender (and not finishing the adding process), the id is set to 0 temporarily.
    //the 'tender', at that point, is marked as 'loaded' in the tender store and the summary is not loaded yet, so the summary's tender-field (which is the tender_id) has not
    //loaded either. But vademecums need to load the tender in order to show the tender title. So, a check for id !== 0 fixes that issue.
    //(note: we're not resetting the tender's loading state to 'loading' when opening a vademecum window, as that would cause a loop on edit_tender, when viewing vademecums there)
    //todo: a 'refreshing' state could address this
    const controller = new AbortController();

    auth.apiService.axiosGET(
      `tenders/${id}/`, //endpoint
      controller.signal, //signal
      loadTenderSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error retrieving tender", //error message
      loadTenderError, //errorFn (gets error back)
      { section: "View Tender -> loadTender" } //data (gets handed back to success fn)
    );
  }
};

export const checkProgress = (auth, type, taskID, objectID, successFN, typeOfTask, searchTenderPayload, fieldName) => {
  //fieldName only exists for edit_tender and vademecum field regen
  const controller = new AbortController();

  const intervals = useTaskManagementStore?.getState()?.intervals;

  if (
    Object.keys(intervals.tenderCreate).length === 0 &&
    Object.keys(intervals.tenderAutoConfirm).length === 0 &&
    Object.keys(intervals.editTenderFieldRegenerate).length === 0 &&
    Object.keys(intervals.vademecumFieldRegenerate).length === 0
  ) {
    devDebug("There is no record of any intervals, yet this function was called, clearing all intervals");
    for (let i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
    return;
  }

  if (fieldName) {
    const interval = useTaskManagementStore?.getState()?.intervals?.[type]?.[objectID]?.[fieldName];
    if (!interval?.intervalID) {
      for (let i = 1; i < 99999; i++) {
        window.clearInterval(i);
      }
      return;
    }
    const date1 = dayjs();
    const date2 = dayjs(interval?.timestamp || 0);
    const diff = date1.diff(date2, "second");
    devDebug(
      `Timestamp of interval with fieldName id ${interval?.intervalID} is ${interval?.timestamp}, difference in seconds is: ${diff}`
    );
    if (diff > 300) {
      clearInterval(interval?.intervalID);
      useTaskManagementStore.getState().deleteTaskInterval(type, objectID, fieldName);
    }
  } else {
    const interval = useTaskManagementStore?.getState()?.intervals?.[type]?.[objectID];
    if (!interval?.intervalID) {
      for (let i = 1; i < 99999; i++) {
        window.clearInterval(i);
      }
      return;
    }
    const date1 = dayjs();
    const date2 = dayjs(interval?.timestamp || 0);
    const diff = date1.diff(date2, "second");
    devDebug(
      `Timestamp of interval without fieldName id ${interval?.intervalID} is ${interval?.timestamp}, difference in seconds is: ${diff}`
    );
    if (diff > 300) {
      clearInterval(interval?.intervalID);
      useTaskManagementStore.getState().deleteTaskInterval(type, objectID, fieldName);
    }
  }

  auth.apiService.axiosGET(
    `async-tasks/task/status/${taskID}`, //endpoint
    controller.signal, //signal
    successFN, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error checking auto-confirm status", //error message
    taskProgressCheckError, //errorFn (gets error back)
    {
      auth: auth,
      fieldName: fieldName, //only relevant on edit_tender or vademecum when regenerating specific field
      type: type,
      taskID: taskID,
      objectID: objectID,
      typeOfTask: typeOfTask,
      searchTenderPayload: searchTenderPayload,
      section: "View Tender -> check progress of auto-confirmation",
    } //data (gets handed back to success fn)
  );
};

//special success function, to determine what to do on success, varies by section. Used as successFN in checkProgress.
export const updateTaskStatus = (resp, data) => {
  devDebug("This is response from taskProgressCheckSuccess: ", resp);
  const task = resp.task ? resp.task : resp;
  //todo: it's not good to have to use resp.task ? resp.task : resp, that needs sorting out
  useTaskManagementStore.getState().setTask(data.type, data.objectID, task, data.fieldName); //fieldName only exists if it's edit_tender or vademecum field regenerate
  if (task.id === "undefined") {
    useTenderSearchStore.getState().removeAutoConfirmCheckBox(data.objectID);
    devDebug("updateTaskStatus -> task id is undefined");
    if (data?.fieldName) {
      devDebug(
        "UNDEFINED -> There was a fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
      clearInterval(
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
    } else {
      devDebug(
        "UNDEFINED -> There was no fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID
      );
      clearInterval(useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID);
    }
  }
  if (task.status === "FAILURE") {
    devDebug(
      "The task failed, clearing interval: ",
      useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID
    );
    if (data?.fieldName) {
      devDebug(
        "FAILURE -> There was a fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
      clearInterval(
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
    } else {
      devDebug(
        "FAILURE -> There was no fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID
      );
      clearInterval(useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID);
    }
    useTaskManagementStore.getState().deleteTaskInterval(data.type, data.objectID, data?.fieldName);
  }
  if (task.status === "SUCCESS") {
    if (data?.fieldName) {
      devDebug(
        "SUCCESS -> There was a fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
      clearInterval(
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID]?.[data.fieldName].intervalID
      );
    } else {
      devDebug(
        "SUCCESS -> There was no fieldName, clearing interval: ",
        useTaskManagementStore?.getState()?.intervals?.[data.type]?.[data?.objectID].intervalID
      );
    }
    useTaskManagementStore.getState().deleteTaskInterval(data.type, data.objectID, data?.fieldName);
    switch (data.typeOfTask) {
      case "searchAutoconfirm":
        useTenderSearchStore.getState().removeAutoConfirmCheckBox(data.objectID);
        refreshTenderSearchTable(
          data.auth,
          data.searchTenderPayload.tabName,
          false, //resetPage
          data.searchTenderPayload.tableParamRef,
          data.searchTenderPayload.tableSettingsName,
          data.searchTenderPayload.admin,
        );
        loadTender(data.auth, data.objectID);
        break;
      case "editAutoconfirm":
        loadTender(data.auth, data.objectID);
        break;
      case "editRegen":
        devDebug(
          "viewTenderUtilities -> editRegen success, showing new value in field: ",
          data.fieldName,
          " resp: ",
          resp
        );
        useViewEditTenderStore.getState().setShowRegeneratedTenderValue(data.fieldName, task.result);
        getQueryHistoryEditTender(data.auth);
        break;
      case "tenderCreate":
        devDebug("viewTenderUtilities -> success for tenderCreate, resp", resp);
        break;
      default:
        devDebug(`viewTenderUtilities -> case ${data.typeOfTask} did not exist, this is data: ${JSON.stringify(data)}`);
    }
  }
};

//this is a generic error handler for any tasks if there's a failure
export const taskProgressCheckError = (err, data) => {
  devDebug("There was an error checking task progress: ", err);
  const intervals = useTaskManagementStore.getState().intervals;
  try {
    clearInterval(intervals?.[data.type]?.[data?.id].intervalID);
    useTaskManagementStore.getState().deleteTaskInterval(data.type, data.id, data.fieldName); //deleting interval rather than task, so that task status remains visible
  } catch { }
  //fieldName is only used when it's edit_tender or vademecum field regenerate
};
export const autoConfirmSuccess = (resp, data) => {
  //todo: it is not good to use resp.task ? resp.task : resp here. needs to be clear what resp will be
  const task = resp.task ? resp.task : resp;
  data.auth.show({
    message: i18n.t("Auto-confirm started"),
    type: "alert_success",
  });
  useTaskManagementStore.getState().setTask("tenderAutoConfirm", data.id, task, null); //null is fieldName, which doesn't exist on autoConfirm (only edit_tender or vademecum fields)
  if (!useTaskManagementStore.getState()?.intervals?.tenderAutoConfirm?.[data?.id]) {
    const interval = setInterval(() => {
      devDebug("!!!!!!!  interval from view Tender autoConfirmSuccess");
      checkProgress(
        //auth, type, taskID, objectID, successFN, typeOfTask, searchTenderPayload, fieldName
        data.auth,
        "tenderAutoConfirm",
        task.task_id ? task.task_id : task.id,
        data.id,
        updateTaskStatus,
        data.typeOfTask,
        data.searchTenderPayload,
        null //null is fieldName, which is only used on edit_tender and vademecum for specific field regenerate
      );
    }, 2000);
    useTaskManagementStore.getState().setTaskInterval("tenderAutoConfirm", data.id, interval, null); //null here is what fieldName would be on edit_tender if regenerating specific field
  }
};

export const autoConfirmError = (err) => {
  if (err?.response?.data?.tender === "Already confirmed") {
    useViewEditTenderStore.getState().setAlreadyConfirmed(true);
    setTimeout(() => {
      useViewEditTenderStore.getState().setAlreadyConfirmed(false);
    }, 2000);
  }
};

export const autoConfirm = (auth, id, typeOfTask, searchTenderPayload) => {
  const controller = new AbortController();

  auth.apiService.axiosPOST(
    `/ai/autoconfirm/`, //endpoint
    controller.signal, //signal
    autoConfirmSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error running auto-confirmation", //error message
    autoConfirmError, //errorFn (gets error back)
    {
      id: id,
      searchTenderPayload: searchTenderPayload,
      auth: auth,
      typeOfTask: typeOfTask,
      payload: { tender: id, run_async: true },
      section: "View Tender -> autoConfirm",
    } //data (gets handed back to success fn)
  );
};

export const getProcedureTypesSuccess = (resp, data) => {
  const setProcedureTypes = useViewEditTenderStore.getState().setProcedureTypes;
  const setProcedureTypesStatus = useViewEditTenderStore.getState().setProcedureTypesStatus;
  setProcedureTypes(resp);
  setProcedureTypesStatus("loaded");
};

export const getProcedureTypesError = (err, data) => {
  const setProcedureTypesStatus = useViewEditTenderStore.getState().setProcedureTypesStatus;
  setProcedureTypesStatus("error");
};

export const getProcedureTypes = (auth) => {
  const setProcedureTypesStatus = useViewEditTenderStore.getState().setProcedureTypesStatus;

  setProcedureTypesStatus("loading");
  const controller = new AbortController();
  auth.apiService.axiosGET(
    `/common/enums/procedure_type/`, //endpoint
    controller.signal, //signal
    getProcedureTypesSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting award criteria", //error message
    getProcedureTypesError, //errorFn (gets error back)
    {
      section: "View Tender -> getProcedureTypes: ",
    } //data (gets handed back to success fn)
  );
};

export const getAwardCriteriaSuccess = (resp, data) => {
  const setAwardCriteria = useViewEditTenderStore.getState().setAwardCriteria;
  const setAwardCriteriaStatus = useViewEditTenderStore.getState().setAwardCriteriaStatus;
  setAwardCriteria(resp);
  setAwardCriteriaStatus("loaded");
};

export const getAwardCriteriaError = (err, data) => {
  const setAwardCriteriaStatus = useViewEditTenderStore.getState().setAwardCriteriaStatus;
  setAwardCriteriaStatus("error");
};

export const getAwardCriteria = (auth) => {
  const setAwardCriteriaStatus = useViewEditTenderStore.getState().setAwardCriteriaStatus;

  setAwardCriteriaStatus("loading");
  const controller = new AbortController();
  auth.apiService.axiosGET(
    `/common/enums/award_criteria/`, //endpoint
    controller.signal, //signal
    getAwardCriteriaSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting award criteria", //error message
    getAwardCriteriaError, //errorFn (gets error back)
    {
      section: "View Tender -> getAwardCriteria: ",
    } //data (gets handed back to success fn)
  );
};

export const deleteLotSuccess = (resp, data) => {
  useViewEditTenderStore.getState().setDeletingLot(null);
  useViewEditTenderStore.getState().setEditingLot(null);
  getLots(new AbortController(), data.tenderID, data.auth);
};

export const deleteLotError = (err, data) => {
  useViewEditTenderStore.getState().setDeletingLot(null);
  useViewEditTenderStore.getState().setEditingLot(null);
  useViewEditTenderStore.getState().setLotError(data.id);
  setTimeout(() => {
    useViewEditTenderStore.getState().setLotError(null);
  }, 3000);
};

export const deleteLot = (controller, tenderID, lot, auth) => {
  if (`${lot.id}`.includes("_new")) {
    useViewEditTenderStore.getState().deleteLot(lot.id);
  } else {
    auth.apiService.axiosDELETE(
      `/tenders/${tenderID}/lots/${lot.id}/`, //endpoint
      controller.signal, //signal
      deleteLotSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error saving the lot", //error message
      deleteLotError, //errorFn (gets error back)
      {
        section: "View Tender -> deleteLot: ",
        id: lot.id,
        auth: auth,
        tenderID: tenderID,
      } //data (gets handed back to success fn)
    );
  }
};

export const saveLotSuccess = (resp, data) => {
  useViewEditTenderStore.getState().setSavingLot(null);
  useViewEditTenderStore.getState().setEditingLot(null);
  if (data.post) {
    if (data.batch) {
      useViewEditTenderStore.getState().addLotWithID(resp);
    } else {
      useViewEditTenderStore.getState().setLot(data.id, resp); //data.id is the original lot's ID
    }
  } else {
    useViewEditTenderStore.getState().setLot(data.id, resp);
  }
};

export const saveLotError = (err, data) => {
  useViewEditTenderStore.getState().setSavingLot(null);
  useViewEditTenderStore.getState().setLotError(data.id);
  setTimeout(() => {
    useViewEditTenderStore.getState().setLotError(null);
  }, 3000);
};

export const saveLot = (controller, tenderID, lot, auth, batch) => {
  const newCPV = lot?.cpv?.split(" - ")?.[1]?.trim() || "00000000-0";
  let newLot = cloneDeep(lot);
  newLot.cpv_code = newCPV;
  if (newCPV === "00000000-0") {
    newLot.cpv = "0";
  }
  let stringID = `${newLot.id}`;
  const newLotWithoutID = cloneDeep(newLot);
  delete newLotWithoutID.id;
  if (stringID.includes("_new")) {
    auth.apiService.axiosPOST(
      `/tenders/${tenderID}/lots/`, //endpoint
      controller.signal, //signal
      saveLotSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error saving the lot", //error message
      saveLotError, //errorFn (gets error back)
      {
        section: "View Tender -> getLots: ",
        payload: newLotWithoutID,
        id: newLot.id,
        post: true,
        batch: batch
      } //data (gets handed back to success fn)
    );
  } else {
    auth.apiService.axiosPATCH(
      `/tenders/${tenderID}/lots/${lot.id}/`, //endpoint
      controller.signal, //signal
      saveLotSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error saving the lot", //error message
      saveLotError, //errorFn (gets error back)
      {
        section: "View Tender -> saveLot: ",
        payload: newLot,
        id: newLot.id,
        post: false,
      } //data (gets handed back to success fn)
    );
  }
};

export const getLotsSuccess = (resp, data) => {
  useViewEditTenderStore.getState().setLots(resp.results);
  useViewEditTenderStore.getState().setLotsStatus("loaded");
};

export const getLotsError = (err) => {
  useViewEditTenderStore.getState().setLotsError(err);
  useViewEditTenderStore.getState().setLotsStatus("error");
};

export const getLots = (controller, tenderID, auth) => {
  auth.apiService.axiosGET(
    `/tenders/${tenderID}/lots?page_size=max`, //endpoint
    controller.signal, //signal
    getLotsSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving the lots", //error message
    getLotsError, //errorFn (gets error back)
    {
      section: "View Tender -> getLots: ",
    } //data (gets handed back to success fn)
  );
};

export const getAttachmentsSuccess = (resp, data) => {
  useViewEditTenderStore.getState().setAttachments(resp.results);
  useViewEditTenderStore.getState().setAttachmentsStatus(useViewEditTenderStore.getState().tender.id);
  if (data.callback) {
    data.callback(false);
  }
};

export const getAttachmentsError = (err) => {
  useViewEditTenderStore.getState().setAttachmentsError(err);
  useViewEditTenderStore.getState().setAttachmentsStatus("error");
};

export const getAttachments = (controller, tenderID, auth, setAttachmentsLoading) => {
  auth.apiService.axiosGET(
    `tenders/${tenderID}/attachments/?page_size=max`, //endpoint
    controller.signal, //signal
    getAttachmentsSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving the attachments", //error message
    getAttachmentsError, //errorFn (gets error back)
    {
      section: "View Tender -> getAttachments: ",
      callback: setAttachmentsLoading,
    } //data (gets handed back to success fn)
  );
};

export const getFieldsSuccess = (resp) => {
  useViewEditTenderStore.getState().setFields(resp.results);
  useViewEditTenderStore.getState().setFieldsStatus("loaded");
};

export const getFieldsError = (err) => {
  useViewEditTenderStore.getState().setFieldsError(err);
  useViewEditTenderStore.getState().setFieldsStatus("error");
};

export function getFields(controller, auth) {
  useViewEditTenderStore.getState().setFieldsStatus("loading");

  auth.apiService.axiosGET(
    `vademecum/fields/?page_size=max`, //endpoint
    controller.signal, //signal
    getFieldsSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving the available summary fields", //error message
    getFieldsError, //errorFn (gets error back)
    { section: "View Tender -> getFields: " } //data (gets handed back to success fn)
  );
}
// export const getPremadeTemplatesSuccess = (resp, data) => {
//   const userTemplates = data.userTemplates.map((tpl) => {
//     return { premade: false, ...tpl };
//   });
//   const premadeTemplates = resp.results.map((tpl) => {
//     return { premade: true, ...tpl };
//   });

//   const combinedTemplates = [...premadeTemplates, ...userTemplates];

//   useViewEditTenderStore.getState().setTemplates(combinedTemplates);
//   useViewEditTenderStore.getState().setTemplatesStatus("loaded");
// };

// export const getPremadeTemplatesError = (err) => {
//   useViewEditTenderStore.getState().setTemplatesError(err);
//   useViewEditTenderStore.getState().setTemplatesStatus("error");
// };
export const getTemplatesSuccess = (resp, data) => {
  // const controller = new AbortController();

  useViewEditTenderStore.getState().setTemplates(resp.results);

  useViewEditTenderStore.getState().setTemplatesStatus("loaded");

  // data.auth.apiService.axiosGET(
  //   `vademecum/premade-templates/?page_size=max`, //endpoint
  //   controller.signal, //signal
  //   getPremadeTemplatesSuccess, //successFn
  //   data.auth.newErrorHandler, //error AlerterFn
  //   "There was an error retrieving the premade templates", //error message
  //   getPremadeTemplatesError, //errorFn (gets error back)
  //   { section: "View Tender -> getFields", userTemplates: resp.results } //data (gets handed back to success fn)
  // );
};

export const getTemplatesError = (err) => {
  useViewEditTenderStore.getState().setTemplatesError(err);
  useViewEditTenderStore.getState().setTemplatesStatus("error");
};

export function getTemplates(controller, refreshing, auth) {
  if (!refreshing) {
    useViewEditTenderStore.getState().setFieldsStatus("loading");
  }
  auth.apiService.axiosGET(
    `vademecum/templates/?page_size=max`, //endpoint
    controller.signal, //signal
    getTemplatesSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error retrieving the user's templates", //error message
    getTemplatesError, //errorFn (gets error back)
    { section: "View Tender -> getFields", auth: auth } //data (gets handed back to success fn)
  );
}

export const getSummariesSuccess = (resp, data) => {
  const id = useViewEditTenderStore.getState().tender.id;
  let inProgressFound = false;

  // for (let summary of resp.results) {
  //   summary.state = "IN_PROGRESS";
  // }

  if (data.isAdminRoute) {
    for (let summary of resp.results) {
      if (summary.tender === id && summary.state === "IN_PROGRESS") {
        devDebug(" summary tab -> loading vademecums -> found an in_progress summary against this tender for admins");
        inProgressFound = true;
        break;
      }
    }
  } else {
    for (let summary of resp.results) {
      if (summary.tender === id && summary.user === data.auth?.user?.id && summary.state === "IN_PROGRESS") {
        devDebug(
          " summary tab -> loading vademecums -> found an in_progress summary against this tender for normal user"
        );
        inProgressFound = true;
        break;
      }
    }
  }

  if (inProgressFound) {
    const interval = useVademecumStore.getState().refreshVademecumsInterval;
    if (interval) {
      devDebug("clearing interval");
      clearInterval(interval);
    } else {
      devDebug(" summary tab -> loading vademecums -> no interval to clear");
    }

    const controller = new AbortController();
    let newInterval = setInterval(() => {
      getSummaries(controller, data.auth, data.isAdminRoute, data.specificTenderID); //controller, auth, isAdminRoute, specificTenderID
    }, 5000);

    useVademecumStore.getState().setRefreshVademecumsInterval(newInterval);
  } else {
    devDebug(" summary tab -> loading vademecums -> no in_progress summary found for this tender");
    const interval = useVademecumStore.getState().refreshVademecumsInterval;
    if (interval) {
      devDebug("clearing interval");
      clearInterval(interval);
    } else {
      devDebug(" summary tab -> loading vademecums -> no interval to clear");
    }
  }

  useViewEditTenderStore.getState().setSummaries(resp.results);
  useViewEditTenderStore.getState().setSummariesStatus("loaded");
};

export const getSummariesError = (err) => {
  useViewEditTenderStore.getState().setSummariesError(err);
  useViewEditTenderStore.getState().setSummariesStatus("error");
};

export const getSummaries = (controller, auth, isAdminRoute, specificTenderID) => {
  if (useAuthStore.getState()?.user?.email) {
    devDebug("getting summaries for user: ", useAuthStore.getState()?.user?.email);
    const id = useViewEditTenderStore.getState().tender.id;
    auth.apiService.axiosGET(
      useAuthStore.getState()?.user?.is_superuser && isAdminRoute
        ? `vademecum/vademecums/?page_size=max&tender_id=${id}`
        : `vademecum/vademecums/?page=1&page_size=max&ordering=created_at_after&user_email=${useAuthStore.getState()?.user?.email}${specificTenderID ? `&tender_id=${id}` : ""
        }`, //endpoint
      controller.signal, //signal
      getSummariesSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error retrieving the summaries", //error message
      getSummariesError, //errorFn (gets error back)
      {
        section: "View Tender -> getFields: ",
        auth: auth,
        isAdminRoute: isAdminRoute,
        specificTenderID: specificTenderID,
      } //data (gets handed back to success fn)
    );
  }
};
