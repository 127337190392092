import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { defaultTabs } from "../Services/strategies/strategyConstants";
import { useTenderStrategyStore } from "./tenderStrategyStore";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  currentTab: defaultTabs,
};

export const useTabStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      verifyTabsExist: () =>
        set((state) => {
          const newEditableStrategiesTenderStrategy =
            useTenderStrategyStore.getState().editableStrategies
              .tender_strategy;
          const newEditableStrategiesEditUser =
            useTenderStrategyStore.getState().editableStrategies.edit_user;
          const newAllStrategies = {
            ...newEditableStrategiesTenderStrategy,
            ...newEditableStrategiesEditUser,
          };

          for (let section of Object.keys(state.currentTab)) {
            if (
              !Object.keys(newAllStrategies).includes(
                `${state.currentTab[section]}`
              )
            ) {
              state.currentTab[section] = initialState.currentTab[section];
            }
          }

          //the below two tests rely on strategies never being empty
          //for edit_user, this test is currently buggy and requires manually adding a strategy
          //but only checking the strategies from the server, ignoring existing draft strategies
          //which isn't great. TODO: fix that somehow
          if (state.currentTab["edit_user"] === null) {
            state.currentTab["edit_user"] = Object.keys(
              newEditableStrategiesEditUser
            )[0];
          }

          if (state.currentTab["tender_strategy"] === null) {
            state.currentTab["tender_strategy"] = Object.keys(
              newEditableStrategiesTenderStrategy
            )[0];
          }
        }),
      setTabID: (section, id) =>
        set((state) => {
          state.currentTab[section] = `${id}`;
        }),
      moveToNextTab: (section, id) =>
        set((state) => {
          const editableStrategies =
            useTenderStrategyStore.getState().editableStrategies;
          let len = Object.keys(editableStrategies[section]).length;
          let idx = 0;
          for (let i = 0; i < len; i++) {
            if (Object.keys(editableStrategies[section])[i] === id) {
              idx = i;
            }
          }
          if (idx === 0) {
            idx = 1;
          } else if (idx === 1) {
            idx = 0;
          } else {
            idx -= 1;
          }

          state.currentTab[section] = Object.keys(editableStrategies[section])[
            idx
          ];
        }),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated"].includes(key)
          )
        ),
      name: "tabs",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tab store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("tab store -> an error happened during hydration", error);
          } else {
            // devDebug("tab store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
