import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import tenders_club_promo_video_en from "../../Assets/Video/tenders_club_promo_video_en.mp4";
import tenders_club_promo_video_es from "../../Assets/Video/tenders_club_promo_video_es.mp4";
import tenders_club_promo_video_it from "../../Assets/Video/tenders_club_promo_video_it.mp4";
import { useComponentsStore } from "../../Stores/componentsStore";

export default function HomeMultiLanguageVideoPopup() {
  const [showMultilanguageVideo, multiLanguageVideoAction] = useComponentsStore(
    (state) => [state.showMultilanguageVideo, state.multiLanguageVideoAction]
  );
  const { t, i18n } = useTranslation();
  function returnPromoVideo() {
    switch (i18n.language.slice(0, 2)) {
      case "en":
        return tenders_club_promo_video_en;
      case "es":
        return tenders_club_promo_video_es;
      case "it":
        return tenders_club_promo_video_it;
      default:
        return tenders_club_promo_video_en;
    }
  }

  return (
    <Modal
      isOpen={showMultilanguageVideo}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-big-modal"
      tabIndex="-1"
      zIndex="11"
      backdrop={true}
      toggle={() => {
        multiLanguageVideoAction(!showMultilanguageVideo);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            multiLanguageVideoAction(!showMultilanguageVideo);
          }}
        >
          {t("video_popup_title")}
        </ModalHeader>
        <ModalBody>
          <div className="th-grey-text mb-4">{t("Video popup copy")}</div>
          <div className="embed-container">
            <video className="tc-tutorial-video" controls>
              <source src={returnPromoVideo()} />
            </video>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              multiLanguageVideoAction(false);
            }}
          >
            {t("Ok")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
