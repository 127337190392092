/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useBlockLayout, useResizeColumns, useSortBy, useGlobalFilter } from "react-table";
import {
  Table,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import Scrollbars from "react-custom-scrollbars-2";
import { TableHeadings } from "../../Components/tables/TableHeadings";
import { useVademecumStore } from "../../Stores/vademecumStore";
import dayjs from "dayjs";
import { useViewEditTenderStore } from "../../Stores/viewEditTender";

export default function VademecumTable({ getTableData }) {
  const { t, i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const getColumnWidth = (headerText) => {
    const magicSpacing = 13;
    return headerText.length * magicSpacing;
  };
  const [vademecumStatus, vademecums, setOrdering, hiddenColumns, setHiddenColumns] = useVademecumStore((state) => [
    state.vademecumStatus,
    state.vademecums,
    state.setOrdering,
    state.hiddenColumns,
    state.setHiddenColumns,
  ]);
  const [
    setSummary,
    setSummaryIDToGET,
    setSummaryNameToGET,
    setSummaryResultModalVisible,
    setSummaryFieldValuesStatus,
    setSummaryStatus,
    setSummaryFieldValues,
    setSummaryFieldValuesError,
  ] = useViewEditTenderStore((state) => [
    state.setSummary,
    state.setSummaryIDToGET,
    state.setSummaryNameToGET,
    state.setSummaryResultModalVisible,
    state.setSummaryFieldValuesStatus,
    state.setSummaryStatus,
    state.setSummaryFieldValues,
    state.setSummaryFieldValuesError,
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "expander",
        width: getColumnWidth(`${t("View")} ++`),
        //todo: make these widths update with the language.
        Cell: ({ row }) => (
          <Button
            color="success"
            className="w-fit h-fit"
            onClick={() => {
              setSummary({});
              setSummaryFieldValues([]);
              setSummaryFieldValuesError("");
              setSummaryStatus("loading");
              setSummaryFieldValuesStatus("loading");
              setSummaryIDToGET(row.values.id);
              setSummaryNameToGET(row.values.name);
              setSummaryResultModalVisible(true);
            }}
          >
            {t("View")}
          </Button>
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: getColumnWidth(`${t("ID")} +<|>`),
      },
      {
        Header: "Name",
        accessor: "name",
        width: getColumnWidth(`${t("Name")} +++++<|>`),
      },
      {
        Header: "Tender",
        accessor: "tender",
        width: getColumnWidth(`${t("Tender")} ++<|>`),
        Cell: ({ row }) => (
          <a
            className="text-decoration-underline-important"
            href={`/admin/view_tender/${row.values.tender}`}
            target="_blank"
            rel="noreferrer"
          >
            {row.values.tender}
          </a>
        ),
      },
      {
        Header: "State",
        accessor: "state",
        width: getColumnWidth(`${t("State")} +++++<|>`),
      },
      {
        Header: "User",
        accessor: "user_email",
        width: getColumnWidth(`${t("User")} +++++++<|>`),
      },
      {
        Header: "Created at",
        accessor: "created_at",
        width: getColumnWidth(`${t("Created at")} ++<|>`),
        Cell: ({ row }) => {
          return dayjs(row.values.created_at).format("DD/MM/YYYY - HH:mm");
        },
      },
      {
        Header: "Completed At",
        accessor: "completed_at",
        width: getColumnWidth(`${t("Completed At")} ++<|>`),
        Cell: ({ row }) => {
          return row.values.completed_at
            ? dayjs(row.values.completed_at).format("DD/MM/YYYY - HH:mm")
            : t("Not completed");
        },
      },
    ],
    [i18n.language]
  );

  const data = useMemo(() => {
    if (vademecums === undefined) {
      return [];
    } else {
      return vademecums;
    }
  }, [vademecums]);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 0,
      maxWidth: 1000,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
        sortBy: [useVademecumStore.getState().ordering],
      },
      manualSortBy: true,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    useGlobalFilter,
    useSortBy
  );

  const inlineBlock = {
    display: "inline-block",
    marginRight: "5px !important",
  };
  function toggleDropDown() {
    setDropdownOpen(!dropdownOpen);
  }

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const debouncedTableRefresh = debounce(() => getTableData());

  return (
    <Card>
      <CardBody className="position-relative">
        {vademecumStatus === "loading" || vademecumStatus === "refreshing" ? (
          <Spinner color="primary" className="position-absolute top-0 left-0" />
        ) : null}
        <div className="th-user-table-flex">
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} className="th-user-select-columns-btn">
            <DropdownToggle
              className="btn-secondary th-width-100 list-users-select-columns-btn"
              id="dropdown-autoclose-outside"
              caret
            >
              {t("Select Columns")}
            </DropdownToggle>
            <DropdownMenu>
              {allColumns.map((column) => {
                if (!hiddenColumns.includes(column.Header) && column.Header !== "") {
                  return (
                    <DropdownItem key={column.id} href="#">
                      <label>
                        {/* force-right-margin can be found in theme.scss. */}
                        <input
                          // math.random method below ensures checkboxes update on click
                          key={Math.random()}
                          className="mr-2 force-right-margin list-users-column-option-checkbox"
                          style={inlineBlock}
                          type="checkbox"
                          onClick={(e) =>
                            setHiddenColumns({
                              name: column.id,
                              checked: e.target.checked,
                            })
                          }
                          {...column.getToggleHiddenProps()}
                        />
                        <div style={inlineBlock} className="ml-2 list-users-column-option-label  tc-text-muted">
                          {t(column.Header)}
                        </div>
                      </label>
                    </DropdownItem>
                  );
                }
                return null;
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <Scrollbars
          className="mt-4 mb-2"
          autoHeight
          autoHeightMax={800}
          hideTracksWhenNotNeeded={true}
          thumbSize={50}
          renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
          renderTrackVertical={(props) => <div {...props} className="track-vertical" />}
          renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
          renderThumbVertical={(props) => <div {...props} className="thumb-vertical" />}
        >
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    if (!hiddenColumns.includes(column.Header)) {
                      return (
                        <th
                          className="d-flex tableHeading"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          onClick={(e) => {
                            //trigger the react-table header onClick manually
                            column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                            let sortedColumn = column.id;
                            let ordering = {};
                            if (sortedColumn === "title_original") {
                              sortedColumn = "title";
                            }
                            switch (column.isSortedDesc) {
                              case true:
                                ordering.desc = undefined;
                                column.isSortedDesc = undefined;
                                ordering.id = "";
                                ordering.url = "";
                                break;
                              case false:
                                ordering.desc = true;
                                column.isSortedDesc = true;
                                ordering.id = sortedColumn;
                                ordering.url = sortedColumn;
                                break;
                              case undefined:
                                ordering.desc = false;
                                column.isSortedDesc = false;
                                ordering.id = sortedColumn;
                                ordering.url = `-${sortedColumn}`;
                                break;
                              default:
                                ordering.desc = undefined;
                                column.isSortedDesc = undefined;
                                ordering.id = "";
                                ordering.url = "";
                            }

                            setOrdering(ordering);
                            debouncedTableRefresh();
                          }}
                        >
                          <TableHeadings column={column} />
                        </th>
                      );
                    }
                    return null;
                  })}
                </tr>
              ))}
            </thead>
            {vademecumStatus === "loaded" || vademecumStatus === "refreshing" ? (
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();
                  return (
                    <React.Fragment key={rowProps.key}>
                      <tr {...rowProps}>
                        {row.cells.map((cell) => {
                          return (
                            <td className="th_table_cell" {...cell.getCellProps()}>
                              {!hiddenColumns.includes(cell.render("Cell").props.column.Header)
                                ? cell.render("Cell")
                                : null}
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>
                    <div className="spinner-border text-primary" role="status"></div>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </Scrollbars>
      </CardBody>
    </Card>
  );
}
