import i18n from "i18next";

export const starterStrategy = {
  name: i18n.t("All Strategies"),
  email: "",
  nuts: [],
  cpvs: [],
  mepa_only: false,
  keywords: "",
  strategy_search_choice: "CPV",
  cpv_count: [],
};

export const defaultStrategies = {
  tender_strategy: {},
  tender_awards: { all: starterStrategy },
  awards_search: {
    market: { ...starterStrategy, name: i18n.t("Market") },
    all: starterStrategy,
  },
  companies_search: {
    market: { ...starterStrategy, name: i18n.t("Market") },
    all: starterStrategy,
  },
  dashboard_logged_in: { all: starterStrategy },
  dashboard_anonymous: { anonymous: starterStrategy },
  edit_user: {},
  search: { all: starterStrategy },
  products_tender_search: { all: starterStrategy },
};

export const defaultTabs = {
  tender_strategy: null,
  tender_awards: "all",
  awards_search: "all",
  companies_search: "all",
  dashboard_logged_in: "all",
  dashboard_anonymous: "anonymous",
  edit_user: null,
  search: "all",
  products_tender_search: "all",
  meilisearch: "all"
};
