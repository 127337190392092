import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { removeDoubleQuotes } from "./SummaryPDFTableStyle";

const styles = StyleSheet.create({
  firstRow: {
    height: "auto",
    fontSize: 10,
    fontWeight: 700,
    borderTop: "1pt solid #cccccc",
    flexDirection: "row",
    display: "flex",
    backgroundColor: "#eeeeee",
  },
  row: {
    height: "auto",
    fontSize: 10,
    borderTop: "1pt solid #cccccc",
    flexDirection: "row",
    display: "flex",
  },
  lastRow: {
    height: "auto",
    fontSize: 10,
    borderTop: "1pt solid #cccccc",
    borderBottom: "1pt solid #cccccc",
    flexDirection: "row",
    display: "flex",
  },
  cell: {
    padding: 5,
    borderLeft: "1px solid #cccccc",
    display: "flex",
    textAlign: "left",
  },
  lastCell: {
    padding: 5,
    borderLeft: "1px solid #cccccc",
    borderRight: "1px solid #cccccc",
    display: "flex",
  },
});

const TableRows = ({ rows }) => {
  let maxCols = rows[0].split("|").length;
  return (
    <Fragment>
      {rows.map((row, rowIDX) => (
        <View
          style={rowIDX === rows.length - 1 ? styles.lastRow : rowIDX === 0 ? styles.firstRow : styles.row}
          key={`${rowIDX}`}
        >
          {row.split("|").map(
            (item, cellIDX) =>
              cellIDX <= maxCols - 1 && (
                <Text
                  key={`${cellIDX}`}
                  style={[cellIDX === maxCols - 1 ? styles.lastCell : styles.cell, { width: `${100 / maxCols}%` }]}
                >
                  {rowIDX === 0 ? removeDoubleQuotes(item).toUpperCase().replace(/_/g, " ") : removeDoubleQuotes(item)}
                </Text>
              )
          )}
        </View>
      ))}
    </Fragment>
  );
};

export default TableRows;
