import React from "react";
import TendersClub_Logo_White from "../Assets/Images/tenders_club_logo_and_tagline_white.png";
import TendersClub_Logo_Blue from "../Assets/Images/tenders_club_logo_and_tagline_purple.png";
import TenderClub_small_logo_white from "../Assets/Images/tenders_club_logo_only_white.png";
import TenderClub_small_logo_dark from "../Assets/Images/tenders_club_logo_only.png";

export default function TendersClubLogo({ size, colour }) {
  if (size === "small" && colour === "light") {
    return <img alt="Tenders Club Logo" className="" src={TenderClub_small_logo_white}></img>;
  } else if (size === "small" && colour === "dark") {
    return <img alt="Tenders Club Logo" className="" src={TenderClub_small_logo_dark}></img>;
  } else if (size === "normal" && colour === "light") {
    return <img alt="Tenders Club Logo" className="th-sidebar-logo-position" src={TendersClub_Logo_White}></img>;
  } else if (size === "normal" && colour === "dark") {
    return <img alt="Tenders Club Logo" className="th-sidebar-logo-position" src={TendersClub_Logo_Blue}></img>;
  }
}
