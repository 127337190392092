import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import tenderStrategyTutorialVideoIT from "../../Assets/Video/tender_strategy_tutorial_video_IT.mp4";
import { useComponentsStore } from "../../Stores/componentsStore";

export default function HelpVideoModal() {
  const [helpVideoAction, showHelpVideoModal] = useComponentsStore((state) => [
    state.helpVideoAction,
    state.showHelpVideoModal,
  ]);
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={showHelpVideoModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-big-modal"
      tabIndex="-1"
      zIndex="11"
      backdrop={true}
      toggle={() => {
        helpVideoAction(!showHelpVideoModal);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            helpVideoAction(!showHelpVideoModal);
          }}
        >
          {t("Help")}
        </ModalHeader>
        <ModalBody>
          <div className="th-grey-text mb-4">
            {t("Please watch the video below for an intro to Tenders.club.")}
          </div>
          <div className="embed-container">
            <video className="tc-tutorial-video" controls>
              <source src={tenderStrategyTutorialVideoIT} />
            </video>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              helpVideoAction(false);
            }}
          >
            {t("Ok")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
