import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  companies: {
    total_pages: 1,
    results: [],
  },
  page: 1,
  pageSize: 10,
  pageInputVisible: false,
  filterColumns: [],
  searchColumns: [],
  orderingTable: { id: "award_count", desc: true },
  orderingURL: "-award_count",
  name: "",
  companiesSearchStatus: "loading",
};

export const useCompaniesSearchStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      setCompaniesSearchStatus: (companiesSearchStatus) =>
        set((state) => {
          state.companiesSearchStatus = companiesSearchStatus;
        }),
      setCompanies: (payload) =>
        set((state) => {
          state.companies = payload;
        }),
      columnChange: (columnID) =>
        set((state) => {
          const filterColumns =
            state.filterColumns;
          if (!filterColumns.includes(columnID)) {
            state.filterColumns.push(columnID);
          } else {
            state.filterColumns =
              filterColumns.filter((col) => col !== columnID);
          }
        }),
      setOrdering: (url, table) =>
        set((state) => {
          state.orderingURL = url;
          state.orderingTable = table;
        }),
      setPage: (payload) =>
        set((state) => {
          state.page = payload;
        }),
      setPageSize: (payload) =>
        set((state) => {
          state.pageSize = payload;
        }),
      setPageInputVisible: (payload) =>
        set((state) => {
          state.pageInputVisible = payload;
        }),
      setFilterColumns: (payload) =>
        set((state) => {
          state.filterColumns = payload;
        }),
      setSearchColumns: (payload) =>
        set((state) => {
          state.searchColumns = payload;
        }),
      setName: (payload) =>
        set((state) => {
          state.name = payload;
        }),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated"].includes(key)
          )
        ),
      name: process.env.REACT_APP_COMPANIES_SEARCH_STORE_KEY,
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tab store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("tab store -> an error happened during hydration", error);
          } else {
            // devDebug("tab store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
