import React from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useVademecumStore } from "../../Stores/vademecumStore";

export default function SearchBox() {
  const { t } = useTranslation();

  const [user_email, setUserEmail, state, setState, reRenderInputs] =
    useVademecumStore((state) => [
      state.user_email,
      state.setUserEmail,
      state.state,
      state.setState,
      state.reRenderInputs,
    ]);

  return (
    <div className="tc-tender-table-filter-box tc-tender-table-filter-box-1">
      <h1 className="tc-tender-table-filter-box-heading">{t("Search")}</h1>
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val2">
        {t("Email")}:
      </div>

      <Input
        key={reRenderInputs}
        name="user_email"
        className="form-control th-tender-table-filters-search-input tc-tender-table-filter-box-val3"
        placeholder={t("Search by user email")}
        type="text"
        onChange={(e) => setUserEmail(e.currentTarget.value)}
        defaultValue={user_email}
      />
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val4">
        {t("State")}:
      </div>
      <div className="d-flex flex-column tc-tender-table-filter-box-val5 align-items-start mt-1">
        <select
          key={reRenderInputs}
          name="state"
          className="form-select me-2"
          onChange={(e) => {
            setState(e.currentTarget.value);
          }}
          defaultValue={state}
        >
          <option value="REQUIRES_MANUAL_COMPLETION">
            {t("Requires Manual Completion")}
          </option>
          <option value="COMPLETED">{t("Completed")}</option>
          <option value="IN_PROGRESS">{t("In Progress")}</option>
        </select>
      </div>
    </div>
  );
}
