import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useViewEditTenderStore } from "../../../../Stores/viewEditTender";
import { useAuth } from "../../../../Services/auth";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiClose, mdiPencilBoxMultipleOutline } from "@mdi/js";
import { useVademecumStore } from "../../../../Stores/vademecumStore";
import devDebug from "../../../../Helpers/debug";
// import cloneDeep from "lodash/cloneDeep";
import { getTemplates } from "../../2_UtilityFunctions/viewTenderUtilities";
import { useAuthStore } from "../../../../Stores/authStore";

export default function SummaryTemplateModal({ isAdminRoute, submitCapability }) {
  const { t } = useTranslation();
  const [showDocuments, setShowDocuments] = React.useState(false);
  const [requestError] = React.useState(null);
  const [
    // user,
    attachments,
    templates,
    summaryTemplateModalVisible,
    setSummaryTemplateModalVisible,
    selectedDocuments,
    setDocumentsModalVisible,
    setFieldSelectionWizardModalVisible,
  ] = useViewEditTenderStore((state) => [
    // state.user,
    state.attachments,
    state.templates,
    state.summaryTemplateModalVisible,
    state.setSummaryTemplateModalVisible,
    state.selectedDocuments,
    state.setDocumentsModalVisible,
    state.setFieldSelectionWizardModalVisible,
  ]);

  const [selectedTemplate, templateToBeEdited, setCreateOrEditMode, setTemplateToBeEdited] = useVademecumStore(
    (state) => [
      state.selectedTemplate,
      state.templateToBeEdited,
      state.setCreateOrEditMode,
      state.setTemplateToBeEdited,
    ]
  );

  return (
    <Modal
      isOpen={summaryTemplateModalVisible}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-screen-width-modal"
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        setSummaryTemplateModalVisible(!summaryTemplateModalVisible);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setSummaryTemplateModalVisible(!summaryTemplateModalVisible);
          }}
        >
          <div className="d-flex flex-column">
            <div className="tc-modal-header">{t("Summary Templates")}</div>
            <div className="tc-modal-subheader ms-1">{`${
              submitCapability ? `(${useAuthStore.getState()?.user?.email})` : ``
            }`}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column tc-flex-gap-10px tc-text-muted">
            <div key="adsasdf" className=" mt-1 mb-1 fw-bold">
              {`${
                submitCapability
                  ? t("Choose which template to request your summary with below")
                  : t("Add or edit templates below")
              }`}
            </div>
            <div className="d-flex flex-column tc-template-modal-max-height-40vh p-1 tc-flex-gap-10px">
              {useAuthStore.getState()?.user?.is_superuser
                ? templates.map((template, idx) => (
                    <TemplateListItem
                      key={`${idx}_${template.premade}`}
                      idx={idx}
                      template={template}
                      submitCapability={submitCapability}
                    />
                  ))
                : templates.map((template, idx) =>
                    template.name ? (
                      <TemplateListItem
                        key={`${idx}_${template.premade}`}
                        idx={idx}
                        template={template}
                        submitCapability={submitCapability}
                      />
                    ) : null
                  )}
              {templates.length === 0 ? <div>{t("There are no templates yet")}</div> : null}
            </div>
            {submitCapability ? (
              <React.Fragment>
                <div key={templateToBeEdited.total_cost} className="d-flex tc-flex-gap-5px">
                  <div className="fw-bold">{t("Balance")}:</div>
                  <div className="fw-bold">{useAuthStore.getState()?.user?.subscription?.vademecum_balance || 0}</div>
                  {templateToBeEdited.total_cost ? (
                    <div className="d-flex tc-flex-gap-5px">
                      <div className="fw-bold">{`- ${templateToBeEdited.total_cost}`}</div>
                      {useAuthStore.getState()?.user?.subscription?.vademecum_balance - templateToBeEdited.total_cost <
                      0 ? (
                        <div className="tc-formerror-colour-only">{`(${t("Insufficient Balance")})`}</div>
                      ) : (
                        <div className="">{`(${t("Remaining")}: ${
                          useAuthStore.getState()?.user?.subscription?.vademecum_balance - templateToBeEdited.total_cost
                        })`}</div>
                      )}
                    </div>
                  ) : null}
                </div>
                {selectedDocuments.length > 0 ? (
                  <div
                    onClick={() => setShowDocuments(showDocuments ? false : true)}
                    className="d-flex justify-content-start cursor-pointer align-items-center mt-4"
                  >
                    <div className="fw-bold">{t("Documents")}</div>
                    <Icon className="tc-text-muted" path={mdiChevronDown} size={1} />
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            {selectedDocuments.length > 0 && showDocuments ? (
              <div className="tc-template-modal-max-height-200px d-flex flex-column tc-flex-gap-5px mb-3">
                {selectedDocuments.map((doc, idx) => {
                  for (let attachment of attachments) {
                    if (attachment.id === doc) {
                      return (
                        <div className="tc-text-muted" key={idx}>
                          {attachment.description}
                        </div>
                      );
                    }
                  }
                  return null;
                })}
              </div>
            ) : null}

            {requestError ? <div className="tc-formerror">{requestError}</div> : null}
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-column flex-sm-row justify-content-between">
          <Button
            onClick={() => {
              setSummaryTemplateModalVisible(false);
            }}
            type="button"
            color="danger"
          >
            {t("Cancel")}
          </Button>
          {isAdminRoute ? (
            <Button
              className="tc-full-width-small"
              onClick={() => {
                setCreateOrEditMode("create");
                setTemplateToBeEdited({
                  id: null,
                  name: "",
                  user: useViewEditTenderStore.getState().user.value,
                  fields: [],
                });
                setSummaryTemplateModalVisible(false);
                setFieldSelectionWizardModalVisible(true);
              }}
              type="button"
              color="secondary"
            >
              {t("Create new")}
            </Button>
          ) : null}
          <Button
            className={`${selectedTemplate ? "" : "disabled"}`}
            onClick={() => {
              setSummaryTemplateModalVisible(false);
              setDocumentsModalVisible(true);
            }}
            type="button"
            color="primary"
          >
            {t("Next")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}

const TemplateListItem = ({ template, submitCapability }) => {
  const { t, i18n } = useTranslation();

  const [setCreateOrEditMode, setTemplateToBeEdited, selectedTemplate, setSelectedTemplate] = useVademecumStore(
    (state) => [
      state.setCreateOrEditMode,
      state.setTemplateToBeEdited,
      state.selectedTemplate,
      state.setSelectedTemplate,
    ]
  );

  const [setSummaryTemplateModalVisible, setFieldSelectionWizardModalVisible] = useViewEditTenderStore((state) => [
    state.setSummaryTemplateModalVisible,
    state.setFieldSelectionWizardModalVisible,
  ]);

  const auth = useAuth();
  const apiService = auth.apiService;
  const controller = new AbortController();
  const deleteTemplateSuccess = (resp, data) => {
    getTemplates(controller, true, auth);
  };
  const deleteTemplateError = (err) => {
    devDebug("There was an error deleting the template: ", err);
  };

  const deleteTemplate = (template) => {
    const controller = new AbortController();
    apiService.axiosDELETE(
      `vademecum/templates/${template.id}`, //endpoint
      // `vademecum/${template.premade ? "premade-" : ""}templates/${template.id}`, //endpoint
      controller.signal, //signal
      deleteTemplateSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error submitting the request", //error message
      deleteTemplateError, //errorFn (gets error back)
      {
        section: "View / Edit Tender -> Summary Tab -> summary template modal -> request summary",
        id: template.id,
        // premade: template.premade,
      } //data (gets handed back to success fn)
    );
  };

  return (
    <div
      className="d-flex align-items-center tc-summary-template-list-item tc-flex-gap-10px ps-1 pe-1 pb-1"
      onClick={() => {
        if (selectedTemplate === template.id) {
          setSelectedTemplate(null);
          setTemplateToBeEdited({
            id: null,
            name: "",
            user: null,
            fields: [],
          });
        } else {
          setSelectedTemplate(template.id);
          setTemplateToBeEdited(template);
        }
      }}
    >
      {submitCapability ? (
        <Input
          className="tc-extra-giant-checkbox tc-checkbox-no-margin tc-small-invisible"
          type="checkbox"
          onChange={() => {}}
          checked={selectedTemplate === template.id}
        />
      ) : null}
      <div className="d-flex align-items-center w-full">
        <div className="d-flex flex-column  w-full">
          <div className="d-flex align-items-center tc-flex-gap-5px w-full">
            <Input
              className=" tc-checkbox-no-margin tc-small-invisible-inverted tc-20px-icon-size"
              type="checkbox"
              onChange={() => {}}
              checked={selectedTemplate === template.id}
            />
            <div className="p-1 fw-bold tc-break-word-anywhere">{`${template.name} (${template.total_cost} credits)`}</div>
            {useAuthStore.getState()?.user?.is_superuser ? (
              <React.Fragment>
                <Icon
                  path={mdiPencilBoxMultipleOutline}
                  className="tc-edit-template tc_grow_on_hover_icon tc-20px-icon-size"
                  title={t("Edit Template")}
                  size={0.8}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTemplateToBeEdited(template);
                    setCreateOrEditMode("edit");
                    setSummaryTemplateModalVisible(false);
                    setFieldSelectionWizardModalVisible(true);
                  }}
                />
                <Icon
                  path={mdiClose}
                  className="ms-auto tc-edit-template tc_grow_on_hover_icon ms-auto me-0 tc-small-invisible-inverted tc-20px-icon-size"
                  title={t("Delete Template")}
                  size={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTemplate(template);
                  }}
                />
              </React.Fragment>
            ) : null}
          </div>

          <div className="d-flex tc-flex-gap-5px tc-small-font">
            {template.fields
              ? template.fields
                  .slice()
                  .sort((a, b) => {
                    return a.position > b.position;
                  })
                  .map((field, idx) =>
                    field.field.translations[i18n.language.slice(0, 2)]
                      ? field.field.translations[i18n.language.slice(0, 2)].display_name
                      : field.field.translations[Object.keys(field.field.translations)[0]].display_name
                  )
                  .join(", ")
              : null}
          </div>
        </div>
        {useAuthStore.getState()?.user?.is_superuser ? (
          <Icon
            path={mdiClose}
            className="tc-edit-template tc_grow_on_hover_icon ms-auto me-3 tc-small-invisible"
            title={t("Delete Template")}
            size={1.5}
            onClick={(e) => {
              e.stopPropagation();
              deleteTemplate(template);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
