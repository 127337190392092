import React from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useVademecumStore } from "../../Stores/vademecumStore";

export default function CompletedAtDateRangeBox() {
  const { t } = useTranslation();

  const [completed_at_after, setCompletedAtAfter, completed_at_before, setCompletedAtBefore] = useVademecumStore(
    (state) => [
      state.completed_at_after,
      state.setCompletedAtAfter,
      state.completed_at_before,
      state.setCompletedAtBefore,
    ]
  );

  return (
    <div className="tc-tender-table-filter-box tc-tender-table-filter-box-3">
      <h2 className="tc-tender-table-filter-box-heading tc-text-muted">{t("Completed At")}</h2>
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val2">{t("After")}:</div>
      <Input
        name="completed_at_after"
        className={`form-control th-tender-table-filters-input th-edit-tenders-input-chrome-fix tc-tender-table-filter-box-val3 ${
          completed_at_after ? "" : "empty-placeholder-color"
        }`}
        placeholder={t("Completed At After")}
        type="date"
        onChange={(e) => setCompletedAtAfter(e.currentTarget.value)}
        defaultValue={completed_at_after}
      />
      <div className="th_edit_tenders_filter_input_label tc-tender-table-filter-box-val4">{t("Before")}:</div>
      <Input
        name="completed_at_before"
        className={`form-control th-tender-table-filters-input th-edit-tenders-input-chrome-fix tc-tender-table-filter-box-val5 ${
          completed_at_before ? "" : "empty-placeholder-color"
        }`}
        placeholder={t("Completed At Before")}
        type="date"
        onChange={(e) => setCompletedAtBefore(e.currentTarget.value)}
        defaultValue={completed_at_before}
      />
    </div>
  );
}
