import { useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import { useAuth } from "../../../../../../Services/auth";
import { useTranslation } from "react-i18next";

const summaryStates = [
  "IN_PROGRESS",
  "COMPLETED",
  "REQUIRES_MANUAL_COMPLETION",
];

const saveSummarySuccess = (resp) => {
  useViewEditTenderStore.getState().setSummaryStatus("loaded");
  useViewEditTenderStore.getState().setSummary(resp);
};

const saveSummaryError = (err) => {
  useViewEditTenderStore.getState().setSummaryError(err);
  useViewEditTenderStore.getState().setSummaryStatus("error");
};

const saveSummary = (auth) => {
  const controller = new AbortController();
  useViewEditTenderStore.getState().setSummaryStatus("saving");
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;

  let editableSummary = useViewEditTenderStore.getState().editableSummary;
  auth.apiService.axiosPATCH(
    `vademecum/vademecums/${summaryIDToGET}/`, //endpoint
    controller.signal, //signal
    saveSummarySuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error saving the summary", //error message
    saveSummaryError, //errorFn (gets error back)
    {
      payload: editableSummary,
      section: "Summary Result Modal -> save summary: ",
    } //data (gets handed back to success fn)
  );
};

export default function StatusSelect() {
  const { t } = useTranslation();

  const auth = useAuth();

  const [summary, summaryStatus, setSummaryState] = useViewEditTenderStore(
    (state) => [state.summary, state.summaryStatus, state.setSummaryState]
  );

  return (
    <div className="d-flex align-items-center tc-flex-gap-10px">
      <div className="fw-bold">{t("Status")}</div>
      <select
        key={`checkedState-${summary.id}_${summaryStatus}_${summary.state}`}
        id="state"
        name="state"
        disabled={summaryStatus === "saving"}
        className={`mt-1 me-1 ${
          summaryStatus === "saving" ? "disabled" : ""
        } form-select`}
        onChange={(e) => {
          setSummaryState(e.target.value);
          saveSummary(auth);
        }}
        defaultValue={summary.state}
      >
        {summaryStates.map((state, idx) => (
          <option key={idx} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
}
