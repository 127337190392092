import devDebug from "../../../../Helpers/debug";
import i18n from "../../../../i18n";
import { useTaskManagementStore } from "../../../../Stores/taskManagementStore";
import { useViewEditTenderStore } from "../../../../Stores/viewEditTender";
import { checkProgress, updateTaskStatus } from "../../2_UtilityFunctions/viewTenderUtilities";
import { MeiliSearch } from "meilisearch";

export const defaultAdditional = {
  offset: 0,
};

export const loadPageOptions = async (q, prevOptions, { offset }) => {
  const meilisearchIndex = useViewEditTenderStore.getState().meilisearchIndex;
  const limit = 10;

  let json;
  try {
    json = await meilisearchIndex.search(q, {
      limit: limit,
      offset: offset,
      showRankingScore: true,
      rankingScoreThreshold: 0.6,
    });
  } catch (e) {
    devDebug("!!!! Edit Tender -> TA Search -> Error: " + e);
    // handle fetch error
    return {
      options: [],
      hasMore: false,
    };
  }

  // handle unexpected json
  if (!json.hits) {
    devDebug("!!!! Edit Tender -> TA Search -> There was no json.hits field");
    return {
      options: [],
      hasMore: false,
    };
  }

  return {
    options: json.hits.map((ta) => ({
      value: ta.id,
      nut_code: ta.nut_code,
      label: `${ta.name}`,
      ta: ta,
    })),
    hasMore: json.hits < 10 ? false : true,

    additional: {
      offset: offset + limit,
    },
  };
};

export const getMeilisearchTokenSuccess = (resp) => {
  useViewEditTenderStore.getState().setMeilisearchToken(resp.meilisearch_token);
  useViewEditTenderStore.getState().setMeilisearchTokenStatus("loaded");

  const client = new MeiliSearch({
    host: process.env.REACT_APP_MEILISEARCH_URL,
    apiKey: resp.meilisearch_token,
  });

  const index = client.index(process.env.REACT_APP_MEILISEARCH_INDEX_TAS);

  useViewEditTenderStore.getState().setMeilisearchIndex(index);
};

export const getMeilisearchTokenError = () => {
  useViewEditTenderStore.getState().setMeilisearchTokenStatus("error");
};

export const getMeilisearchToken = (auth) => {
  const controller = new AbortController();

  auth.apiService.axiosGET(
    `common/third-party/auth/tokens/`, //endpoint
    controller.signal, //signal
    getMeilisearchTokenSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting the meilisearch token", //error message
    getMeilisearchTokenError, //errorFn (gets error back)
    {
      section: "Edit Tender -> Search Tendering Authorities -> getMeilisearchToken",
    } //data (gets handed back to success fn)
  );
};
export const getGeneratableFieldsSuccess = (resp) => {
  useViewEditTenderStore.getState().setGeneratableFields(resp?.results || []);
};

export const getGeneratableFieldsError = () => {
  devDebug("There was an error getting the generatable fields");
};

export const getGeneratableFields = (auth) => {
  const controller = new AbortController();

  auth.apiService.axiosGET(
    `ai/generatable-fields/?page_size=max`, //endpoint
    controller.signal, //signal
    getGeneratableFieldsSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting the generatable fields", //error message
    getGeneratableFieldsError, //errorFn (gets error back)
    {
      section: "Edit Tender -> Get generatable fields",
    } //data (gets handed back to success fn)
  );
};

export const generateFieldSuccess = (resp, data) => {
  devDebug("This is response from editTenderFieldRegenerate button: ", resp);
  data.auth.show({
    message: i18n.t("Regenerating field"),
    type: "alert_success",
  });
  useTaskManagementStore.getState().setTask("editTenderFieldRegenerate", data.id, resp, data.fieldName); //resp not resp.task here, as this endpoint doesn't return a task (which is a different structure from the other ai endpoint
  setTimeout(() => {
    if (!useTaskManagementStore.getState()?.intervals?.editTenderFieldRegenerate?.[data?.id]?.[data.fieldName]) {
      let interval = setInterval(() => {
        devDebug("!!!!!!!  interval from view Tender generateFieldSuccess");
        checkProgress(
          //auth, type, taskID, objectID, successFN, typeOfTask, searchTenderPayload, fieldName
          data.auth,
          "editTenderFieldRegenerate",
          resp.task_id,
          data.id,
          updateTaskStatus,
          "editRegen",
          {}, //only used when it's auto-confirm on search tender
          data.fieldName
        );
      }, 2000);
      useTaskManagementStore.getState().setTaskInterval("editTenderFieldRegenerate", data.id, interval, data.fieldName); //fieldName is only used when on edit_tender, regenerating fields
    }
  }, 1000);
};

export const generateFieldError = (err) => {
  devDebug("There was an error regenerating the tender's field: ", err);
};

export const generateField = (auth, fieldName) => {
  const tenderID = useViewEditTenderStore.getState().tender.id;

  const controller = new AbortController();

  auth.apiService.axiosPOST(
    `/ai/generate-field/`, //endpoint
    controller.signal, //signal
    generateFieldSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error regenerating the tender's field", //error message
    generateFieldError, //errorFn (gets error back)
    {
      id: tenderID,
      fieldName: fieldName,
      auth: auth,
      payload: { tender_id: tenderID, field_name: fieldName },
      section: "Edit Tender -> regenerate",
    } //data (gets handed back to success fn)
  );
};

export function getQueryHistoryEditTender(auth) {
  const tenderID = useViewEditTenderStore.getState().tender.id;
  //TODO: this needs to change to filter by a specific field
  const controller = new AbortController();
  auth.apiService.axiosGET(
    `ai/query-history/?object_id=${tenderID}&is_object_field=true&page_size=max`, //endpoint
    controller.signal, //signal
    getQueryHistorySuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error getting the query history", //error message
    getQueryHistoryError, //errorFn (gets error back)
    {
      tender_id: tenderID,
      auth: auth,
      section: "Summaries -> get query history",
    } //data (gets handed back to success fn)
  );
}

const getQueryHistorySuccess = (resp, data) => {
  useViewEditTenderStore.getState().setQueryHistoryItems(resp.results);
};
const getQueryHistoryError = (err, data) => {
  devDebug("There was an error getting query history: ", err);
};
