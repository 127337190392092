import { Button } from "reactstrap";
import i18n from "../i18n";
import TendersClubLogo from "./tendersClubLogo";
import { useComponentsStore } from "../Stores/componentsStore";

export function ErrorComponent({ error, resetErrorBoundary }) {
  return (
    <div className="tc-text-muted text-center d-flex  align-items-center justify-content-center w-full h-full ">
      <div className=" d-flex flex-column align-items-center justify-content-center w-full h-full tc-flex-gap-10px tc-max-width-250px">
        <div className="tc-formerror-colour-only">
          {i18n.t("Sorry, it looks like something went wrong.")}
        </div>
        <div>{i18n.t("You can now either:")}</div>
        <div className="d-flex flex-column justify-content-center">
          <Button
            onClick={() => resetErrorBoundary()}
            type="button"
            color="primary"
          >
            {i18n.t("Retry")}
          </Button>
          <div>{`(${i18n.t("Clears current section's settings")})`}</div>
        </div>
        {i18n.t("or")}
        <a href="/contact" target="_blank">
          {i18n.t("Contact us")}
        </a>
      </div>
    </div>
  );
}

export function ErrorComponentWithMessage({ message }) {
  return (
    <div className="tc-text-muted text-center d-flex  align-items-center justify-content-center w-full h-full ">
      <div className=" d-flex flex-column align-items-center justify-content-center w-full h-full tc-flex-gap-10px tc-max-width-250px">
        <div className="tc-formerror-colour-only">{message}</div>
        <a href="/contact" target="_blank">
          {i18n.t("Contact us")}
        </a>
      </div>
    </div>
  );
}

export function MainAppErrorComponent({ error, resetErrorBoundary }) {
  const [isDark] = useComponentsStore((state) => [state.isDark]);

  return (
    <div className="mt-5  tc-text-muted text-center d-flex  align-items-center justify-content-center w-full h-full ">
      <div className="d-flex flex-column align-items-center justify-content-center w-full h-full tc-flex-gap-10px tc-max-width-250px">
        <TendersClubLogo size="normal" colour={isDark ? "light" : "dark"} />
        <div className="mt-4 tc-formerror-colour-only">
          {i18n.t("Sorry, it looks like something went wrong.")}
        </div>
        <div>{i18n.t("You can now either:")}</div>
        <div className="d-flex flex-column justify-content-center">
          <Button
            onClick={() => resetErrorBoundary()}
            type="button"
            color="primary"
          >
            {i18n.t("Retry")}
          </Button>
          <div>{`(${i18n.t(
            "Clears all searches and filters, keeps strategies and preferences."
          )})`}</div>
        </div>
        {i18n.t("or")}
        <a href="/contact" target="_blank">
          {i18n.t("Contact us")}
        </a>
      </div>
    </div>
  );
}
