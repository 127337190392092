import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationDE from "./Locales/de/translation.json";
import translationIT from "./Locales/it/translation.json";
import translationFR from "./Locales/fr/translation.json";
import translationES from "./Locales/es/translation.json";
import translationENG from "./Locales/en/translation.json";

// the translations
const resources = {
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  en: {
    translation: translationENG,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    debug: process.env.REACT_APP_DEBUG === "1",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
      bindI18n: "languageChanged loaded",
    },
  });

export default i18n;
