import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import UserSelectionComponent from "../../1_Components/userSelectionComponent";
import { useVademecumStore } from "../../../../Stores/vademecumStore";
import { useAuth } from "../../../../Services/auth";
import { FileUploader } from "react-drag-drop-files";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useViewEditTenderStore } from "../../../../Stores/viewEditTender";
import { TextareaAutosize } from "@mui/material";
import i18n from "../../../../i18n";
import { returnTenderTitle } from "./SummaryResultsModal/summaryResultModal";
// import devDebug from "../../../../Helpers/debug";

export const MemoizedEmailUserModal = React.memo(EmailUserModal);

const resetFields = () => {
  const instance = useVademecumStore.getState().instance;
  const updateInstance = useVademecumStore.getState().updateInstance;

  const summaryNameToGET = useViewEditTenderStore.getState().summaryNameToGET;
  const tender = useViewEditTenderStore.getState().tender;

  let title = returnTenderTitle(tender, i18n.language.slice(0, 2));

  useVademecumStore
    .getState()
    .setEmailSubject(
      `${i18n.t("Summary for tender")}: ${title.length > 100 ? title.substring(0, 100) + "..." : title}`
    );

  useVademecumStore
    .getState()
    .setEmailBody(
      `${i18n.t("Here is your summary called")}: ${summaryNameToGET}.\n\n${i18n.t("For tender")}: ${title}`
    );

  updateInstance();

  setTimeout(() => {
    var file = new File([instance.blob], `${summaryNameToGET.replace(/[ &/\\#,+()$~%.'":*?<>{}]/g, "-")}.pdf`);
    useVademecumStore.getState().setUploads([file]);
  }, 100);
};

export default function EmailUserModal() {
  const instance = useVademecumStore.getState().instance;
  const updateInstance = useVademecumStore.getState().updateInstance;

  const { t } = useTranslation();
  const auth = useAuth();
  const apiService = auth.apiService;

  const [
    setEmailUserModalVisible,
    emailUserModalVisible,
    emailSubject,
    setEmailSubject,
    emailBody,
    setEmailBody,
    uploads,
    addUploads,
    removeUpload,
  ] = useVademecumStore((state) => [
    state.setEmailUserModalVisible,
    state.emailUserModalVisible,
    state.emailSubject,
    state.setEmailSubject,
    state.emailBody,
    state.setEmailBody,
    state.uploads,
    state.addUploads,
    state.removeUpload,
  ]);

  React.useEffect(() => {
    resetFields(instance, updateInstance);
  }, [emailUserModalVisible]);

  const handleChange = (uploads) => {
    addUploads(uploads);
  };

  const emailUserSuccess = () => {
    setEmailUserModalVisible(false);
  };
  const emailUserError = () => {};

  const emailUser = () => {
    useVademecumStore.getState().clearEmptyUploads();
    let uploads = useVademecumStore.getState().uploads;

    const user = useViewEditTenderStore.getState().user;

    const controller = new AbortController();
    const formData = new FormData();
    for (let file of uploads) {
      formData.append("attachments", file);
    }
    formData.append("subject", emailSubject);
    formData.append("body", emailBody);
    apiService.axiosPOSTFormData(
      `/UAadmin/users/${user.value}/send-email/`, //endpoint
      controller.signal, //signal
      emailUserSuccess, //successFn
      auth.newErrorHandler, //error AlerterFn
      "There was an error uploading the files", //error message
      emailUserError, //errorFn (gets error back)
      {
        section: "View Tender -> email user modal",
        formData: formData,
      }
    );
  };

  return (
    <Modal
      isOpen={emailUserModalVisible}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-screen-width-modal"
      tabIndex="-1"
      zIndex="11"
      toggle={() => {
        setEmailUserModalVisible(!emailUserModalVisible);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setEmailUserModalVisible(!emailUserModalVisible);
          }}
        >
          {t("Email User")}
        </ModalHeader>
        <ModalBody>
          <div className="tc-text-muted">
            <div className="d-flex flex-column tc-flex-gap-10px p-1">
              <UserSelectionComponent title={t("Select the user to email")} getCPVTemplates={undefined} />
              <Input
                className="form-control"
                placeholder={t("Subject")}
                value={emailSubject}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                }}
              />
              <TextareaAutosize
                className="form-control"
                type="textarea"
                placeholder={t("Message")}
                value={emailBody}
                onChange={(e) => {
                  setEmailBody(e.target.value);
                }}
              />
              <FileUploader
                label={t("Upload File")}
                multiple={true}
                handleChange={handleChange}
                name="file"
                children={
                  <div className="tc-attachments-file-upload py-1 px-2">
                    <p>{`${t("Click here to add files")}`}</p>
                  </div>
                }
              />

              <div className="d-flex flex-column tc-flex-gap-10px lot-form-control">
                {uploads.map((upload, idx) => (
                  <div key={`${idx}`} className="d-flex align-items-center tc-flex-gap-10px">
                    <div className="tc-text-nowrap">{upload.name}</div>
                    <Icon
                      className="tc-text-muted cursor-pointer tc_grow_on_hover_icon "
                      size={1}
                      path={mdiClose}
                      onClick={() => removeUpload(upload.name)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between flex-column flex-sm-row justify-content-between">
          <Button
            className="tc-full-width-small"
            onClick={() => {
              setEmailUserModalVisible(false);
            }}
            type="button"
            color="danger"
          >
            {t("Cancel")}
          </Button>
          <Button
            className="tc-full-width-small me-0 me-sm-auto"
            onClick={() => {
              resetFields(instance, updateInstance);
            }}
            type="button"
            color="secondary"
          >
            {t("Reset Fields")}
          </Button>
          <Button
            className={`tc-full-width-small ${emailSubject.length === 0 || emailBody.length === 0 ? "disabled" : ""}`}
            onClick={() => {
              emailUser();
            }}
            type="button"
            color="primary"
          >
            {t("Send")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
