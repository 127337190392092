import { useMyTendersStore } from "../../Stores/myTenders";

export const getFavouritesSuccess = (resp) => {
    useMyTendersStore.getState().setFavourites(resp.results);
    useMyTendersStore.getState().setFavouritesStatus("loaded");
};

export const getFavouritesError = (err) => {
    useMyTendersStore.getState().setFavouritesError(err);
    useMyTendersStore.getState().setFavouritesStatus("error");
};

export const getFavourites = (auth) => {
    const controller = new AbortController();
    auth.apiService.axiosGET(
        `/user/favorites`, //endpoint
        controller.signal, //signal
        getFavouritesSuccess, //successFn
        auth.newErrorHandler, //error AlerterFn
        "There was an error retrieving the favourited tenders", //error message
        getFavouritesError, //errorFn (gets error back)
        { section: "My Tenders -> getFavourites: " } //data (gets handed back to success fn)
    );
};

export const getDislikesSuccess = (resp) => {
    useMyTendersStore.getState().setDislikes(resp.results);
    useMyTendersStore.getState().setDislikesStatus("loaded");
};

export const getDislikesError = (err) => {
    useMyTendersStore.getState().setDislikesError(err);
    useMyTendersStore.getState().setDislikesStatus("error");
};

export const getDislikes = (auth) => {
    const controller = new AbortController();
    auth.apiService.axiosGET(
        `/user/dislikes`, //endpoint
        controller.signal, //signal
        getDislikesSuccess, //successFn
        auth.newErrorHandler, //error AlerterFn
        "There was an error retrieving the disliked tenders", //error message
        getDislikesError, //errorFn (gets error back)
        { section: "My Tenders -> getDislikes: " } //data (gets handed back to success fn)
    );
};
