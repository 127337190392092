import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  tenderAwardsCompaniesLoading: true,
  tenderAwardsShortTermLoading: true,
  tenderAwardsLongTermLoading: true,
  tenderAwardsSuppliers: true,
  tenderAwardsSuppliersLoading: true,
  tenderAwardsShortTermMarket: true,
  tenderAwardsShortTermMarketLoading: true,
  tenderAwardsCompanies: [],
  tenderAwardsShortTerm: {},
  tenderAwardsLongTerm: [],
  tenderAwardsMetric: "total_amount",
  user: {},
  userLoaded: false,
};

export const useTenderAwardsStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      // ------------------- TENDER AWARDS --------------
      setTenderAwardsMetric: (payload) =>
        set((state) => {
          state.tenderAwardsMetric = payload;
        }),
      setTenderAwardsShortTermMarket: (payload) =>
        set((state) => {
          state.tenderAwardsShortTermMarket = payload;
        }),
      setTenderAwardsShortTermMarketLoading: (payload) =>
        set((state) => {
          state.tenderAwardsShortTermMarketLoading = payload;
        }),
      setTenderAwardsSuppliers: (payload) =>
        set((state) => {
          state.tenderAwardsSuppliers = payload;
        }),
      setTenderAwardsSuppliersLoading: (payload) =>
        set((state) => {
          state.tenderAwardsSuppliersLoading = payload;
        }),
      setTenderAwardsCompanies: (payload) =>
        set((state) => {
          state.tenderAwardsCompanies = payload;
        }),
      setTenderAwardsShortTerm: (payload) =>
        set((state) => {
          state.tenderAwardsShortTerm = payload;
        }),
      setTenderAwardsLongTerm: (payload) =>
        set((state) => {
          state.tenderAwardsLongTerm = payload;
        }),
      setTenderAwardsCompaniesLoading: (payload) =>
        set((state) => {
          state.tenderAwardsCompaniesLoading = payload;
        }),
      setTenderAwardsShortTermLoading: (payload) =>
        set((state) => {
          state.tenderAwardsShortTermLoading = payload;
        }),
      setTenderAwardsLongTermLoading: (payload) =>
        set((state) => {
          state.tenderAwardsLongTermLoading = payload;
        }),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated"].includes(key)
          )
        ),
      name: "tenderAwards",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tenderAwardsStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "tenderAwardsStore -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug("tenderAwardsStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
