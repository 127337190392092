import { mdiArrowAll } from "@mdi/js";
import Icon from "@mdi/react";
import { useTranslation } from "react-i18next";

export default function FieldValueListItemNormal({ field }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="d-flex align-items-center tc-flex-gap-10px">
      <Icon
        path={mdiArrowAll}
        className="tc-move-summary-field-arrows tc_grow_on_hover_icon cursor-pointer"
        title={t("Move field up or down")}
        size={0.8}
      />
      <div className="d-flex flex-column align-items-center mb-2 w-full">
        <div className="fw-bold me-auto">
          {`${
            field.field.translations[i18n.language.slice(0, 2)]
              ? field.field.translations[i18n.language.slice(0, 2)].display_name
              : field.field.translations[
                  Object.keys(field.field.translations)[0]
                ].display_name
          }`}
          :
        </div>
        <div className="tc-summary-field-value-container">{field.value}</div>
      </div>
    </div>
  );
}
