import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Services/auth";
import { useNavigate } from "react-router-dom";
import { useComponentsStore } from "../../Stores/componentsStore";
import { useStrategies } from "../../Services/strategies/strategyService";

export default function UnsavedPopup() {
  const navigate = useNavigate();
  let auth = useAuth();
  const strategyService = useStrategies();
  const [setShowUnsavedPopup, showUnsavedPopup] = useComponentsStore(
    (state) => [state.setShowUnsavedPopup, state.showUnsavedPopup]
  );

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={showUnsavedPopup}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="tc-big-modal"
      tabIndex="-1"
      zIndex="11"
      backdrop={true}
      toggle={() => {
        setShowUnsavedPopup(!showUnsavedPopup);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setShowUnsavedPopup(!showUnsavedPopup);
          }}
        >
          {t("Unsaved Strategy")}
        </ModalHeader>
        <ModalBody>
          <div className="th-grey-text mb-4">
            {t(
              "You have unsaved tender strategies. If you log out, they will not be saved."
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="tc-full-width-small"
            type="button"
            color="danger"
            onClick={() =>
              auth.signout(() => {
                strategyService.setFirstRun(true);
                strategyService.setStrategiesStatus("loading");
                window.location.reload();
              })
            }
          >
            {t("Logout")}
          </Button>
          <Button
            className="tc-full-width-small"
            type="button"
            color="primary"
            onClick={() => {
              setShowUnsavedPopup(false);
              navigate("/tender_strategy");
            }}
          >
            {t("Go to Tender Strategies")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
