import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import dayjs from "dayjs";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const initialState = {
  tasks: {
    tenderCreate: {},
    tenderAutoConfirm: {},
    editTenderFieldRegenerate: {},
    vademecumFieldRegenerate: {},
  },
  intervals: {
    tenderCreate: {},
    tenderAutoConfirm: {},
    editTenderFieldRegenerate: {},
    vademecumFieldRegenerate: {},
  },
};

export const useTaskManagementStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },

      // ------------------------- TASKS -------------------------

      setTask: (
        type,
        id, //id here could be tender ID or vademecum ID
        task,
        fieldName //only used when it's a specific field on edit_tender or vademecum
      ) =>
        set((state) => {
          if (fieldName) {
            if (!state?.tasks?.[type]?.[id]) {
              state.tasks[type][id] = {};
            }
            state.tasks[type][id][fieldName] = task;
          } else {
            state.tasks[type][id] = task; //id here could be tender ID or vademecum ID
          }
        }),
      deleteTask: (
        type,
        id, //id here could be tender ID or vademecum ID
        fieldName //only used when it's a specific field on edit_tender or vademecum
      ) =>
        set((state) => {
          if (fieldName) {
            delete state.tasks[type][id][fieldName];
          } else {
            delete state.tasks[type][id]; //id here could be tender ID or vademecum ID
          }
        }),
      setTaskInterval: (
        type,
        id, //id here could be tender ID or vademecum ID
        interval,
        fieldName //only used when it's a specific field on edit_tender or vademecum
      ) =>
        set((state) => {
          devDebug("setting interval: ", interval, " with fieldName: ", fieldName, " and id: ", id, " of type: ", type);
          if (fieldName) {
            if (!state?.intervals?.[type]?.[id]) {
              state.intervals[type][id] = {};
            }
            state.intervals[type][id][fieldName] = { intervalID: interval, timestamp: dayjs() };
          } else {
            state.intervals[type][id] = { intervalID: interval, timestamp: dayjs() }; //id here could be tender ID or vademecum ID
          }
        }),
      deleteTaskInterval: (
        type,
        id, //id here could be tender ID or vademecum ID
        fieldName //only used when it's a specific field on edit_tender or vademecum
      ) =>
        set((state) => {
          if (fieldName) {
            if (state?.intervals?.[type]?.[id]?.[fieldName]) {
              delete state.intervals[type][id][fieldName]; //id here could be tender ID or vademecum ID
            }
          } else {
            if (state?.intervals?.[type]?.[id]) {
              delete state.intervals[type][id]; //id here could be tender ID or vademecum ID
            }
          }
        }),

      resetState: () => set(initialState),
    })),
    {
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !["_hasHydrated"].includes(key))),
      name: "taskManagement",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tender search store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "tender search store -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug("tender search store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
