import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
import all_cpv_trees from "../Assets/CPVs/all_cpv_trees.json";
import default_mapdata from "../Assets/Mapdata/default_mapdata.json";
import { persist, createJSONStorage } from "zustand/middleware";
import { mergeDeepLeft } from "ramda";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import i18n from "../i18n";
// import devDebug from "../Helpers/debug";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const defaultMapState = {
  level: 0,
  displayOption: "tender_count",
  refreshMap: true,
};

const initialState = {
  currentStep: 0,
  cpvTree: all_cpv_trees[i18n.language.slice(0, 2) || "en"],
  cpvs: [],
  cpvError: "",
  companyDescription: "",
  mapData: default_mapdata,
  mapState: defaultMapState,
  nuts: [],
  refreshLayer: false,
  above1000CPVs: false,
  stopRetries: false,
  checkboxCPVs: {},
  normalCPVs: {},
};

export const useRegisterStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      // ------------------- INIT ----------------------
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      setCurrentStep: (payload) =>
        set((state) => {
          state.currentStep = payload;
        }),
      // ------------------- CPVs --------------
      setCPVs: (payload) =>
        set((state) => {
          let convertedPayload = {};
          if (Array.isArray(payload)) {
            for (let cpv of payload) {
              convertedPayload[cpv] = true;
            }
            payload = convertedPayload;
          }

          state.cpvs = Object.keys(payload).filter((key) => !payload?.[key]?.partialChecked);
          let withPartialCheckedObj = {};
          let withoutPartialCheckedObj = {};
          let hasPartialAttribute = false;
          if (Object.keys(payload).length > 0 && payload[Object.keys(payload)[0]].hasOwnProperty("partialChecked")) {
            hasPartialAttribute = true;
          }
          if (hasPartialAttribute) {
            state.checkboxCPVs = payload;
            for (let cpv of Object.keys(payload)) {
              if (!payload[cpv].partialChecked) {
                withoutPartialCheckedObj[cpv] = true;
              }
            }
            state.normalCPVs = withoutPartialCheckedObj;
          } else {
            for (let cpv of Object.keys(payload)) {
              withPartialCheckedObj[cpv] = { checked: true, partialChecked: false };
            }
            state.checkboxCPVs = withPartialCheckedObj;
            state.normalCPVs = payload;
          }
        }),
      addCPV: (cpv) =>
        set((state) => {
          state.cpvs = [...state.cpvs, cpv];
          state.checkboxCPVs[cpv] = { checked: true, partialChecked: false };
          state.normalCPVs[cpv] = true;
        }),
      deleteCPV: (payload) =>
        set((state) => {
          state.cpvs = state.cpvs.filter((cpv) => cpv !== payload);
          delete state.checkboxCPVs[payload];
          delete state.normalCPVs[payload];
        }),
      setStopRetries: (stopRetries) =>
        set((state) => {
          state.stopRetries = stopRetries;
        }),
      setCPVError: (payload) =>
        set((state) => {
          state.cpvError = payload;
        }),
      setCompanyDescription: (payload) =>
        set((state) => {
          state.companyDescription = payload;
        }),
      setAbove1000CPVs: (payload) =>
        set((state) => {
          state.above1000CPVs = payload;
        }),
      // ------------------------ nuts map ---------------------
      setMetric: (payload) =>
        set((state) => {
          state.metric = payload;
        }),
      setDisplayOption: (payload) =>
        set((state) => {
          state.mapState.displayOption = payload;
        }),
      setLevel: (payload) =>
        set((state) => {
          state.mapState.level = payload;
        }),
      setRefreshMap: (payload) =>
        set((state) => {
          state.mapState.refreshMap = payload;
        }),
      setNUTS: (payload) =>
        set((state) => {
          state.nuts = payload;
        }),
      // ------------------- RESET ------------------------
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(Object.entries(state).filter(([key]) => !["_hasHydrated"].includes(key))),
      name: "registerSavedData",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("maps store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("maps store -> an error happened during hydration", error);
          } else {
            // devDebug("maps store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
