import Toggle from "react-toggle";
import { useColorScheme } from "./darkModePersist";
import "react-toggle/style.css";
import { useTranslation } from "react-i18next";
const DarkmodeToggle = () => {
  const { t } = useTranslation();
  const { isDark, setIsDark } = useColorScheme();
  return (
    <Toggle
      className="tc-darkmode-toggle"
      checked={isDark === "dark"}
      onChange={(event) => {
        setIsDark(event.target.checked ? "dark" : "light");
        let themeLink = document.getElementById("app-theme");
        if (event.target.checked) {
          themeLink.href = `${window.location.origin}/lara-dark-purple/theme.css`;
        } else {
          themeLink.href = `${window.location.origin}/lara-light-purple/theme.css`;
        }
      }}
      icons={{ checked: "🌙", unchecked: "🔆" }}
      aria-label={t("Dark mode")}
    />
  );
};

export default DarkmodeToggle;
