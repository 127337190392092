import React from "react";
// import { Spinner } from "reactstrap";
// import loading_gif_256 from "../Assets/Images/gif/loading_gif_256.gif";
// import loading_compressed_256 from "../Assets/Images/gif/loading_compressed_256.gif";
import loading_compressed_256_fast from "../Assets/Images/gif/loading_compressed_256_fast.gif";
// import logo_video from "../Assets/Images/gif/logo_video.mp4";
import { useTranslation } from "react-i18next";

export default function ScreenSpinner({ copy }) {
  const { t } = useTranslation();
  return (
    <div className="th-screen-filling-spinner-container">
      {/* <Spinner color="primary" /> */}
      {/* <video className="tc-loading-gif" autoplay muted loop playsinline preload="metadata">
        <source src={logo_video} />
      </video> */}
      <img className="tc-loading-gif" alt={t("Tenders Club Logo Animation")} src={loading_compressed_256_fast} />
      <div className="ms-2 tc-text-muted tc-max-width-70vw">{`${copy ? t(copy) : t("Initialising Section...")}`}</div>
    </div>
  );
}
