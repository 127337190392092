import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  animatedOnce: false,
  success: false,
  dashboardData: {
    dashboard_logged_in: {
      all: {
        total_tenders: 0,
        recent_tenders: 0,
        tenders_expiring_in_3_days: 0,
      },
    },
    dashboard_anonymous: {
      anonymous: {
        total_tenders: 0,
        recent_tenders: 0,
        tenders_expiring_in_3_days: 0,
      },
    },
  },
  getError: false,
  dashboardDataLoading: true,
  shortTermLoadingMarket: true,
  shortTermLoadingStrategy: true,
  longTermLoading: true,
  shortTermDataMarket: { last_month_avg: "0", last_week: "0", this_week: "0" },
  shortTermDataStrategy: {
    last_month_avg: "0",
    last_week: "0",
    this_week: "0",
  },
  longTermChartData: {
    all: [],
    strategy_cpvs: [],
    strategy_nuts: [],
    strategy: [],
  },
  donutData: {},

  submitErrors: { all: "" },
  errType: { all: "" },
};

export const useDashboardStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      // ------------------- DASHBOARD -----------------------
      setSuccess: (payload) =>
        set((state) => {
          state.success = payload;
        }),
      setAnimatedOnce: (payload) =>
        set((state) => {
          state.animatedOnce = payload;
        }),
      setDashboardData: (resp, section, id) =>
        set((state) => {
          state.dashboardData[section][id] = resp;
          state.loading = false;
          state.success = true;
        }),
      setGetError: (payload) =>
        set((state) => {
          state.getError = payload;
        }),
      setDashboardDataLoading: (payload) =>
        set((state) => {
          state.dashboardDataLoading = payload;
        }),
      setShortTermLoadingMarket: (payload) =>
        set((state) => {
          state.shortTermLoadingMarket = payload;
        }),
      setShortTermLoadingStrategy: (payload) =>
        set((state) => {
          state.shortTermLoadingStrategy = payload;
        }),
      setLongTermLoading: (payload) =>
        set((state) => {
          state.longTermLoading = payload;
        }),
      setShortTermDataMarket: (payload) =>
        set((state) => {
          state.shortTermDataMarket = payload;
        }),
      setShortTermDataStrategy: (payload) =>
        set((state) => {
          state.shortTermDataStrategy = payload;
        }),
      setLongTermChartData: (payload) =>
        set((state) => {
          state.longTermChartData = payload;
        }),
      setDonutData: (payload) =>
        set((state) => {
          state.donutData = payload;
        }),
      setDonutLoading: (payload) =>
        set((state) => {
          state.donutLoading = payload;
        }),
      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "_hasHydrated",
                "tenderStrategyStatus",
                "tenderStrategies",
                "CPVStage",
                "NUTSMapStage",
                "user",
                "userLoaded",
                "strategyIDs",
                "animatedOnce",
                "dashboardDataLoading",
              ].includes(key)
          )
        ),
      name: "dashboardStore",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("dashboardStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "dashboardStore -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug("dashboardStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
