import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import default_mapdata from "../Assets/Mapdata/default_mapdata.json";
import i18n from "i18next";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { defaultStrategies } from "../Services/strategies/strategyConstants";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  _hasHydrated: false,
  defaultStrategies: { ...defaultStrategies },
};

export const useDefaultStrategyStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      compileDefaultStrategies: (existingStrategies) =>
        set((state) => {
          let nuts = [];
          for (let id of Object.keys(existingStrategies)) {
            for (let nut of existingStrategies[id].nuts) {
              for (let id of Object.keys(default_mapdata))
                if ((id.includes(nut) || id === nut) && !nuts.includes(nut)) {
                  nuts.push(nut);
                }
            }
          }
          state.defaultStrategies["dashboard_logged_in"].all.nuts = nuts;
          //TODO: figure out why this hack is needed, when the names should be populated by strategyConstants.js
          state.defaultStrategies["dashboard_logged_in"].all.name =
            i18n.t("All Strategies");
          state.defaultStrategies["tender_awards"].all.name =
            i18n.t("All Strategies");
          state.defaultStrategies["awards_search"].all.name =
            i18n.t("All Strategies");
          state.defaultStrategies["awards_search"].market.name =
            i18n.t("Market");
          state.defaultStrategies["companies_search"].all.name =
            i18n.t("All Strategies");
          state.defaultStrategies["companies_search"].market.name =
            i18n.t("Market");
          state.defaultStrategies["dashboard_anonymous"].anonymous.name =
            i18n.t("All Strategies");
          state.defaultStrategies["search"].all.name = i18n.t("All Strategies");
        }),

      resetState: () => {
        set(initialState);
      },
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !["_hasHydrated"].includes(key)
          )
        ),
      name: "defaultStrategies",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) =>
        mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("useDefaultStrategyStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "useDefaultStrategyStore -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug(
            //   "useDefaultStrategyStore -> hydration finished, state: ",
            //   state
            // );
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
