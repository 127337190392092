import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useComponentsStore } from "../../Stores/componentsStore";

export function DislikeWarning() {
  const { t } = useTranslation();
  const [showDislikeWarningModal, setShowDislikeWarningModal] = useComponentsStore((state) => [
    state.showDislikeWarningModal,
    state.setShowDislikeWarningModal,
  ]);

  return (
    <Modal
      isOpen={showDislikeWarningModal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className=""
      tabIndex="-1"
      zIndex="11"
      backdrop={true}
      toggle={() => {
        setShowDislikeWarningModal(!showDislikeWarningModal);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setShowDislikeWarningModal(!showDislikeWarningModal);
          }}
        >
          {t("A note on dislikes")}
        </ModalHeader>
        <ModalBody>
          <div className="th-grey-text">{t("dislike_modal_copy")}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="tc-full-width-small"
            type="button"
            color="primary"
            onClick={() => {
              setShowDislikeWarningModal(false);
            }}
          >
            {t("Ok")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
